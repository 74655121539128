import {Price} from "./AssociationTypes";
import i18n from "../i18n/i18n";
import moment from "moment/moment";
import StringUtils from "./StringUtils";
export class FormattingUtils {

  static formatPrice(price: Price): string {

    if (!price)
      return ''

    return i18n.t('currency.integerWithSymbol', {value: price.amount, currency: price.currency}) || ''
  }

  static getCurrencySymbol(currencyCode: string): string {

    const dummyValue = i18n.t('currency.integerWithSymbol', {value: 0, currency: currencyCode})

    return dummyValue.replaceAll("0", "").trim()
  }

  static formatInteger(value: number): string {

    return i18n.t('number.integer', {value}) || ''
  }

  static formatDateLong(value: Date | string | undefined): string {

    if (!value)
      return ''

    const date = moment(value).toDate()

    return i18n.t('date.dateLong', {value: date})
  }

  static formatDateShort(value: Date | string | undefined): string {

    if (!value)
      return ''

    const date = moment(value).toDate()

    return i18n.t('date.dateShort', {value: date})
  }

  static formatDateTime(value: Date | string | undefined): string {

    if (!value)
      return ''

    const date = moment(value).toDate()

    return i18n.t('date.dateTimeShort', {value: date})
  }

  static formatDateTimeWithDay(value: Date | string | undefined): string {

    if (!value)
      return ''

    const date = moment(value).toDate()

    return StringUtils.capitalizeString(i18n.t('date.dateTimeWithDay', {value: date}))
  }

  static formatDateTimeWithDayAndYear(value: Date | string | undefined): string {

    if (!value)
      return ''

    const date = moment(value).toDate()

    return StringUtils.capitalizeString(i18n.t('date.dateTimeWithDayAndYear', {value: date}))
  }

  static formatDateTimeShorter(value: Date | string): string {

    if (!value)
      return ''

    const date = moment(value).toDate()

    return i18n.t('date.dateTimeShorter', {value: date})
  }

  static formatDateTimeShortest(value: Date | string): string {

    if (!value)
      return ''

    const date = moment(value).toDate()

    return i18n.t('date.dateTimeShortest', {value: date})
  }

  static formatDateMonthOnlyShort(month: number): string {

    const date = moment().month(month).toDate()

    return i18n.t('date.dateMonthOnlyShort', {value: date})
  }

  static formatDayOnlyShort(value: Date | string): string {

    if (!value)
      return ''

    const date = moment(value).toDate()

    return i18n.t('date.dateDayOnlyShort', {value: date})
  }

  static formatTime(value: Date | string): string {
    if (!value)
      return ''

    const date = moment(value).toDate()

    return i18n.t('date.time', {value: date})
  }

  static formatDrawingTime(endDate: Date | string): string {
    const drawDate = moment.utc(endDate)
    const now = moment.utc()

    const diffMinutes = drawDate.diff(now, 'minutes')

    if (diffMinutes <= 1) {
      return i18n.t('components.activeDraw.counter.drawingNow')
    } else if (diffMinutes < 60) {
      return i18n.t('components.activeDraw.counter.drawingLessThanHour', {value: diffMinutes})
    } else if (drawDate.isSame(now, 'day')) {
      return i18n.t('components.activeDraw.counter.drawingToday', {value: FormattingUtils.formatTime(endDate)})
    } else if (drawDate.isSame(now.add(1, 'day'), 'day')) {
      return i18n.t('components.activeDraw.counter.drawingTomorrow', {value: FormattingUtils.formatTime(endDate)})
    } else {
      return i18n.t('components.activeDraw.counter.drawingInFuture', {date: FormattingUtils.formatDateTimeShortest(endDate), time: FormattingUtils.formatTime(endDate)})
    }
  }
}
