import {TeamDTO} from "../../../apis/teams-api";
import {AssociationDTO} from "../../../apis/associations-api";
import {PageResponse} from "../../../apis/core/api-base";
import {DrawDTO} from "../../../apis/draws-api";
import {DrawStatus, DrawType, Price} from "../../../utils/AssociationTypes";
import {DrawUtils} from "../../../utils/DrawUtils";
import {TEAM_PRIMARY_COLOR_DEFAULT, TEAM_SECONDARY_COLOR_DEFAULT} from "../../../constants/colors"
import {TeamUtils} from '../../../utils/TeamUtils';
import {ImageUtils} from "../../../utils/ImageUtils";
import {UserActionUtils} from "../../../utils/UserUtils";
import {CategoryDTO} from "../../../apis/categories-api";

export type DrawsTab = DrawStatus
export type DrawsData = {
  totalItems: number
  hasMoreItems: boolean
}

export const TAB_PARAM_TO_DRAWS_TAB_MAPPING: Record<string, DrawStatus> = {
  'active': "ACTIVE",
  'pending': "PENDING",
  'closed': "CLOSED",
  'cancelled': "CANCELLED"
}

export const getDrawsTabFromSearchParam = (searchParamValue: string | null): DrawsTab => {
  return (searchParamValue && TAB_PARAM_TO_DRAWS_TAB_MAPPING[searchParamValue]) || 'ACTIVE'
}

export const getTabParamFromDrawsTab = (drawsTab: DrawsTab): string | undefined => {
  const entry = Object.entries(TAB_PARAM_TO_DRAWS_TAB_MAPPING).find(([key, value]) => value === drawsTab)

  if (entry)
    return entry[0]
}

export type DrawsActiveDrawModel = {
  dto: DrawDTO
  drawName: string,
  homeTeamId: string,
  teamName: string,
  teamLogoUrl: string,
  otherTeamName?: string,
  otherTeamLogoUrl?: string,
  lowestWinningAmount: Price,
  numberOfSoldTickets?: number,
  startTime: string
  closingTime: string
  ticketPrice: Price
  numberOfPlayers: number
  type: DrawType
  objective?: string
  firstBrandingColor: string
  secondBrandingColor: string
  playerIncome: Price
  jackpotSize: Price
  teamGender?: string
  otherTeamGender?: string
  teamCategory?: CategoryDTO
  otherTeamCategory?: CategoryDTO
  createdBy:string
  homeGame?: boolean
}

export type DrawsActiveDrawDataModel =  DrawsData & {
  items: DrawsActiveDrawModel[]
}
export type DrawsPendingDrawDataModel =  DrawsData & {
  items: DrawsPendingDrawModel[]
}
export type DrawsClosedDrawDataModel =  DrawsData & {
  items: DrawsClosedDrawModel[]
}
export type DrawsCancelledDrawDataModel =  DrawsData & {
  items: DrawsCanceledDrawModel[]
}

export type DrawsPendingDrawModel = {
  dto: DrawDTO
  drawId: string
  drawName: string
  salesStartTime: string
  lowestWinningAmount: Price
  playerIncome: Price
  numberOfSoldTickets: number
  numberOfPlayers: number
  homeGame?: boolean
  createdBy: string
}

export type DrawsClosedDrawModel = {
  dto: DrawDTO
  drawName: string
  ticketPrice: Price
  numberOfPlayers: number
  numberOfTickets: number
  startTime: string
  closingTime: string
  associationIncome: Price
  drawType: DrawType
  createdBy: string
  jackpotSize: Price
  homeGame?: boolean
  winningTicketNumber?: string
}

export type DrawsCanceledDrawModel = {
  dto: DrawDTO
  drawName: string
  salesStartTime: string
  numberOfPlayers: number
  numberOfTickets: number
  createdBy: string
}

export type DrawsPageModel = {
  activeDrawsData: DrawsActiveDrawDataModel,
  pendingDrawsData: DrawsPendingDrawDataModel,
  closedDrawsData: DrawsClosedDrawDataModel,
  canceledDrawsData: DrawsCancelledDrawDataModel
}

const createOrUpdateItems = <Model>(draws: Model[], currentDraws: Model[] | undefined, isLoadMore: boolean): Model[] => {

  // If we are 'loading more' that means we want to concat to existing data, otherwise we replace the date
  if(isLoadMore)
    return [...currentDraws!, ...draws]
  else
    return draws
}

export function createModel(
  selectedTeam: TeamDTO,
  selectedAssociation: AssociationDTO,
  selectedTab: DrawsTab,
  activeDrawsPage: PageResponse<DrawDTO>,
  pendingDrawsPage: PageResponse<DrawDTO>,
  closedDrawsPage: PageResponse<DrawDTO>,
  canceledDrawsPage: PageResponse<DrawDTO>,
  pageNumber: number,
  model: DrawsPageModel | null,
  isLoadMore: boolean
): DrawsPageModel {
  return {
    activeDrawsData: {
      items: createOrUpdateItems<DrawsActiveDrawModel>(createActiveDrawsModels(activeDrawsPage.items), model?.activeDrawsData?.items, isLoadMore),
      totalItems: activeDrawsPage.totalItems,
      hasMoreItems: pageNumber + 1 < activeDrawsPage.totalPages
    },
    pendingDrawsData: {
      items: createOrUpdateItems<DrawsPendingDrawModel>(createPendingDrawsModels(pendingDrawsPage.items), model?.pendingDrawsData?.items, isLoadMore),
      totalItems: pendingDrawsPage.totalItems,
      hasMoreItems: pageNumber + 1 < pendingDrawsPage.totalPages
    },
    closedDrawsData: {
      items: createOrUpdateItems<DrawsClosedDrawModel>(createClosedDrawsModels(closedDrawsPage.items), model?.closedDrawsData?.items, isLoadMore),
      totalItems: closedDrawsPage.totalItems,
      hasMoreItems: pageNumber + 1 < closedDrawsPage.totalPages
    },
    canceledDrawsData: {
      items: createOrUpdateItems<DrawsCanceledDrawModel>(createCanceledDrawsModels(canceledDrawsPage.items), model?.canceledDrawsData?.items, isLoadMore),
      totalItems: canceledDrawsPage.totalItems,
      hasMoreItems: pageNumber + 1 < canceledDrawsPage.totalPages
    }
  }
}

const createActiveDrawsModels = (dtos: DrawDTO[]): DrawsActiveDrawModel[] => {
  return dtos.map(draw => {

    return {
      dto: draw,
      drawName: draw.name,
      homeTeamId: draw.team.id,
      teamName: TeamUtils.getTeamName(draw.team),
      teamLogoUrl: ImageUtils.createTeamLogoUrl(draw.team, draw.association)!,
      otherTeamName: TeamUtils.getTeamName(draw.opponentTeam!),
      otherTeamLogoUrl: ImageUtils.createTeamLogoUrl(draw.opponentTeam, draw.opponentTeam?.association),
      lowestWinningAmount: DrawUtils.calculateAssociationIncome(draw),
      numberOfSoldTickets: DrawUtils.calculateTotalTickets(draw),
      startTime: draw.salesStart,
      closingTime: draw.salesEnd,
      numberOfPlayers: DrawUtils.calculateTotalPlayers(draw),
      ticketPrice: draw.price,
      type: draw.type,
      firstBrandingColor: draw.association?.branding?.color || TEAM_PRIMARY_COLOR_DEFAULT,
      secondBrandingColor: draw.association?.branding?.secondColor || TEAM_SECONDARY_COLOR_DEFAULT,
      playerIncome: DrawUtils.calculatePlayerIncome(draw),
      jackpotSize: DrawUtils.calculateJackpotSize(draw),
      teamGender: draw.team.gender,
      otherTeamGender: draw.opponentTeam && draw.opponentTeam.gender,
      teamCategory: draw.team.category,
      otherTeamCategory: draw.opponentTeam && draw.opponentTeam.category,
      createdBy: UserActionUtils.getUserName(draw.created),
      homeGame: draw.isHomeGame,
      objective: draw.objective
    }
  })
}

const createPendingDrawsModels = (dtos: DrawDTO[]): DrawsPendingDrawModel[] => {
  return dtos.map(draw => {
    return {
      dto: draw,
      drawId: draw.id,
      drawName: draw.name,
      salesStartTime: draw.salesStart,
      playerIncome: DrawUtils.calculatePlayerIncome(draw),
      lowestWinningAmount: DrawUtils.calculateAssociationIncome(draw),
      numberOfPlayers: DrawUtils.calculateTotalPlayers(draw),
      numberOfSoldTickets: DrawUtils.calculateTotalTickets(draw),
      homeGame: draw.isHomeGame,
      createdBy: UserActionUtils.getUserName(draw.created)
    }
  })
}

const createClosedDrawsModels = (dtos: DrawDTO[]): DrawsClosedDrawModel[] => {
  return dtos.map(draw => {

    const associationIncome = DrawUtils.calculateAssociationIncome(draw)

    return {
      dto: draw,
      drawName: draw.name,
      associationIncome,
      startTime: draw.salesStart,
      closingTime: draw.salesEnd,
      numberOfPlayers: draw.results?.stats.totalPlayers || 0,
      numberOfTickets: draw.results?.stats.totalRows || 0,
      ticketPrice: draw.price,
      drawType: draw.type,
      createdBy: UserActionUtils.getUserName(draw.created),
      jackpotSize: DrawUtils.calculateJackpotSize(draw),
      homeGame: draw.isHomeGame,
      winningTicketNumber: DrawUtils.getFormattedWinnerNumber(draw)
    }
  })
}

const createCanceledDrawsModels = (dtos: DrawDTO[]): DrawsCanceledDrawModel[] => {
  return dtos.map(it => {
    return {
      dto: it,
      drawName: it.name,
      salesStartTime: it.salesStart,
      numberOfPlayers: DrawUtils.calculateTotalPlayers(it),
      numberOfTickets: DrawUtils.calculateTotalTickets(it),
      createdBy: it.created.userName || it.created.byUser?.id,
    }
  })
}
