import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";
import {AcceptInvitationPageModel, createModel} from "./model";
import {AdministratorDTO, AdministratorInvitationDTO, administratorsApi} from "../../../apis/administrators-api";

interface AcceptInvitationState {
  isLoading: boolean
  loadingError: ErrorObject | null,
  token: string | null,
  model: AcceptInvitationPageModel | null
  isSaving: boolean
  savingError: ErrorObject | null
  acceptedSuccessfully: boolean
}

const initialState: AcceptInvitationState = {
  isLoading: false,
  loadingError: null,
  model: null,
  isSaving: false,
  savingError: null,
  token: null,
  acceptedSuccessfully: false,
}

export const fetchData = createAsyncThunk<[AdministratorDTO, AdministratorInvitationDTO]>(
  'acceptInvitation/fetchData',
  async (payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState
    const token = rootState.acceptInvitation.token!

    return Promise.all([
      rootState.settings.user!,
      administratorsApi.getInvitation(token)
    ])
  },
)

export const acceptInvitation = createAsyncThunk<string, void>(
  'acceptInvitation/accept',
  async (payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState
    const token = rootState.acceptInvitation.token!

    return administratorsApi.acceptInvitation(token)
  }
)

export const acceptInvitationSlice = createSlice({
  name: 'acceptInvitation',
  initialState,
  reducers: {
    initPage: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload
      state.isLoading = false
      state.savingError = null
      state.acceptedSuccessfully = false
    },
    clearPage: (state) => {
      state.acceptedSuccessfully = false
    },
    clearSavingError: (state) => {
      state.savingError = null
    }
  },
  extraReducers: (builder) => {

    // Fetch data
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      const [user, invitation] = action.payload
      state.isLoading = false
      state.model = createModel(user, invitation)
    })

    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

    // Accept
    builder.addCase(acceptInvitation.pending, (state) => {
      state.isSaving = true
      state.savingError = null
    })

    builder.addCase(acceptInvitation.fulfilled, (state) => {
      state.isSaving = false
      state.acceptedSuccessfully = true
    })

    builder.addCase(acceptInvitation.rejected, (state, action) => {
      state.isSaving = false
      state.savingError = ErrorUtils.createErrorObjectFromAction(action)
    })
  },
})

// Actions
export const {
  initPage,
  clearPage,
  clearSavingError
} = acceptInvitationSlice.actions

// Selectors
export const selectIsLoading = (state: RootState) => state.acceptInvitation.isLoading
export const selectLoadingError = (state: RootState) => state.acceptInvitation.loadingError
export const selectModel = (state: RootState) => state.acceptInvitation.model
export const selectToken = (state: RootState) => state.acceptInvitation.token
export const selectIsSaving = (state: RootState) => state.acceptInvitation.isSaving
export const selectSavingError = (state: RootState) => state.acceptInvitation.savingError
export const selectAcceptedSuccessfully = (state: RootState) => state.acceptInvitation.acceptedSuccessfully

export default acceptInvitationSlice.reducer
