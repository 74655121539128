import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'

import {Dialog} from "../components/dialogs/Types";
import StringUtils from "../utils/StringUtils";
import {RootState} from "../store/store";
import {ErrorObject} from "../utils/ErrorUtils";

export type AppDialogsState = {
  // Array of currently displayed dialogs
  dialogs: Dialog[]
}

const initialState: AppDialogsState = {
  dialogs: [],
}

/**
 * We do closing with a timeout to let enough time for the animation to kick in.
 */
export const closeDialog = createAsyncThunk<void, Dialog>(
  'appDialogs/closeDialog',
  (payload, thunkApi) => {
    thunkApi.dispatch(hideDialog(payload))
    setTimeout(() => {
      thunkApi.dispatch(removeDialog(payload))
    }, 300)
  },
)

export const appDialogsSlice = createSlice({
  name: 'appDialogs',
  initialState,
  reducers: {
    addDialog: (state, action: PayloadAction<Dialog>) => {
      const dialog = {...action.payload, id: StringUtils.randomId(), visible: true}
      state.dialogs.push(dialog)
    },
    hideDialog: (state, action) => {
      state.dialogs = state.dialogs.map(it => it.id === action.payload.id ? {...it, visible: false} : it)
    },
    removeDialog: (state, action) => {
      state.dialogs = state.dialogs.filter(it => it.id !== action.payload.id)
    },
    startDialog: (state, action) => {
      state.dialogs = state.dialogs.map(it => it.id === action.payload.id ? {...it, error: null, saving: true} : it)
    },
    errorDialog: (state, action: PayloadAction<{ dialog: Dialog, error: ErrorObject }>) => {
      state.dialogs = state.dialogs.map(it => it.id === action.payload.dialog.id ? {
        ...it,
        error: action.payload.error,
        saving: false
      } : it)
    },
    setDialogSecondaryText: (state, action: PayloadAction<{ id: string, text: string }>) => {
      state.dialogs = state.dialogs.map(it => it.id === action.payload.id ? {
        ...it,
        secondaryText: action.payload.text
      } : it)
    }
  },
  extraReducers: (builder) => {
  },
})

// Actions
export const {addDialog, removeDialog, hideDialog, startDialog, errorDialog, setDialogSecondaryText} = appDialogsSlice.actions

// Selectors
export const selectDialogs = (state: RootState) => state.appDialogs.dialogs

export default appDialogsSlice.reducer
