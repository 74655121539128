import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";
import {AdministratorsAddModel, createModel} from "./model";
import {
  AdministratorDTO,
  AdministratorInsertDTO,
  administratorsApi,
  AdministratorUpdateDTO
} from "../../../apis/administrators-api";
import {AssociationDTO} from "../../../apis/associations-api";
import {TeamDTO} from "../../../apis/teams-api";

export type AdministratorsAddFormData = {
  personalNumber: string
  firstName: string
  lastName: string
  phone: string
  email: string
}


interface AdministratorsAddState {
  isLoading: boolean
  loadingError: ErrorObject | null,
  model: AdministratorsAddModel | null
  isInsert: boolean
  isSaving: boolean
  savingError: ErrorObject | null
  hasSavedSuccessfully: boolean
}

const initialState: AdministratorsAddState = {
  isLoading: false,
  loadingError: null,
  model: null,
  isInsert: true,
  isSaving: false,
  savingError: null,
  hasSavedSuccessfully: false
}

export const fetchData = createAsyncThunk<[TeamDTO, AssociationDTO, AdministratorDTO | undefined], string | undefined>(
  'administratorsAdd/fetchData',
  async (payload, thunkApi) => {
    const administratorId = payload
    const rootState = thunkApi.getState() as RootState
    return Promise.all([
      rootState.settings.selectedTeam!,
      rootState.settings.selectedAssociation!,
      administratorId ? administratorsApi.getAdministrator(payload) : Promise.resolve(undefined)
    ])
  },
)

export const saveData = createAsyncThunk<string | undefined | void, AdministratorsAddFormData>(
  'administratorsAdd/saveData',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState
    const state = rootState.administratorsAdd
    const settings = rootState.settings

    if (state.isInsert) {

      const dto: AdministratorInsertDTO = {
        lastName: payload.lastName,
        firstName: payload.firstName,
        personalNumber: payload.personalNumber,
        email: payload.email,
        phoneNumber: payload.phone,
        associationId: settings.selectedAssociation!.id,
      }

      return administratorsApi.addAdministrator(dto)
    } else {

      const dto: AdministratorUpdateDTO = {
        id: state.model!.dto!.id!,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phoneNumber: payload.phone,
        associationId: settings.selectedAssociation!.id
      }

      return administratorsApi.updateAdministrator(state.model!.id!, dto)
    }
  },
)

export const administratorsAddSlice = createSlice({
  name: 'administratorsAdd',
  initialState,
  reducers: {
    initPage: (state) => {
      state.model = null
      state.isLoading = false
      state.loadingError = null
      state.isSaving = false
      state.savingError = null
      state.hasSavedSuccessfully = false
    },
    clearPage: (state) => {
      state.hasSavedSuccessfully = false
    }
  },
  extraReducers: (builder) => {

    // Fetching data
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      const [team, association, administrator] = action.payload
      state.isLoading = false
      state.isInsert = !administrator
      state.model = createModel(administrator, association, team)
    })

    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

    // Saving data
    builder.addCase(saveData.pending, (state) => {
      state.isSaving = true
      state.savingError = null
    })

    builder.addCase(saveData.fulfilled, (state, action) => {
      state.isSaving = false
      state.hasSavedSuccessfully = true
    })

    builder.addCase(saveData.rejected, (state, action) => {
      state.isSaving = false
      state.savingError = ErrorUtils.createErrorObjectFromAction(action)
    })

  },
})

// Actions
export const {initPage, clearPage} = administratorsAddSlice.actions

// Selectors
export const selectIsLoading = (state: RootState) => state.administratorsAdd.isLoading
export const selectLoadingError = (state: RootState) => state.administratorsAdd.loadingError
export const selectModel = (state: RootState) => state.administratorsAdd.model
export const selectIsInsert = (state: RootState) => state.administratorsAdd.isInsert
export const selectIsSaving = (state: RootState) => state.administratorsAdd.isSaving
export const selectSavingError = (state: RootState) => state.administratorsAdd.savingError
export const selectHasSavedSuccessfully = (state: RootState) => state.administratorsAdd.hasSavedSuccessfully

export default administratorsAddSlice.reducer
