import React, {CSSProperties} from 'react'
import clsx from "clsx";
import './tear-border.css';

type TearBorderProps = {
  className?: string,
  radius?: number,
  top?: boolean
  style?: CSSProperties
}

export const TearBorder = ({className, top = false, style}: TearBorderProps) => {
  return (
    <div className={clsx(
      'tear-border',
      top ? 'top' : 'bottom',
      'w-full h-4',
      className)}
         style={style}
    >
    </div>
  )
}
