import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./store/store";
import i18n from './i18n/i18n'
import auth from "./auth/auth";

/**
 * Globally defined variables.
 */
declare global {
  var initializationTime: Date
}

/**
 * Make sure splash is at least this long for better user experience.
 */
const SPLASH_TIMEOUT = process.env.NODE_ENV === 'development' ? 1000 : 3000

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const initializeApp = () => {
// window.initializationTime is defined in index.html file
  const initializationDiff = new Date().getMilliseconds() - window.initializationTime.getMilliseconds()
  const initializationDelay = Math.max(SPLASH_TIMEOUT - initializationDiff, 0)

  setTimeout(() => {

    console.log('Initialized i18n:', i18n)

    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App/>
        </Provider>
      </React.StrictMode>
    );
  }, initializationDelay)
}

auth.initKeycloak(initializeApp, () => {
  initializeApp();
  alert('Error logging in. Contact administrator or try again.')
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
