export default class StringUtils {

  // NOTE: Copied from vee-validate in order to have the exact email rules
  static EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  static join(...values: string[]) {
    if (!values) {
      return ''
    }

    return values.join(' ')
  }

  static containsIgnoreCase(text: string, needle: string) {
    if (!needle) {
      return true
    }

    if (!text) {
      return false
    }

    text = text.toLowerCase()
    needle = needle.toLowerCase()

    return text.includes(needle)
  }

  static randomId() {
    return (Math.random() + 1).toString(36).slice(2, 7)
  }

  static isNotEmpty(text: string) {
    return text && !!text.trim()
  }


  static getFirstCharacter(text: string) {
    if (!text || !text.trim()) {
      return ''
    }

    return text.charAt(0).toUpperCase()
  }

  static capitalizeString(text: string): string {
    if (!text || typeof text !== 'string') {
      return ''
    }

    return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
  }

  static replaceNonBreakingSpaces(text: string): string {
    if (!text || typeof text !== 'string') {
      return ''
    }

    return text.replace(/\u00a0/g, ' ')
  }

}
