export class LocalStorageUtils {
  static ASSOCIATION_ID_NAME = 'swswa_aid'
  static TEAM_ID_NAME = 'swswa_tid'
  static TEAM_SWITCH_INFO = 'team_switch_info'
  static COOKIES_INFO = 'cookies_info'

  static saveAssociationSelection(associationId?: string): void {

    if (associationId)
      localStorage.setItem(this.ASSOCIATION_ID_NAME, associationId)
  }

  static saveTeamSelection(teamId?: string): void {

    if (teamId)
      localStorage.setItem(this.TEAM_ID_NAME, teamId)
  }

  static getAssociationSelection(): string | null {
    return localStorage.getItem(this.ASSOCIATION_ID_NAME)
  }

  static getTeamSelection(): string | null {
    return localStorage.getItem(this.TEAM_ID_NAME)
  }

  static getTeamSwitchInfoSeen(): boolean {
    return localStorage.getItem(this.TEAM_SWITCH_INFO) === 'seen'
  }

  static saveTeamSwitchInfoSeen(): void {
    return localStorage.setItem(this.TEAM_SWITCH_INFO, 'seen')
  }

  static getCookiesInfoSeen(): boolean {
    return localStorage.getItem(this.COOKIES_INFO) === 'seen'
  }

  static saveCookiesInfoSeen(): void {
    return localStorage.setItem(this.COOKIES_INFO, 'seen')
  }
}
