import React, {ErrorInfo} from "react";

type ErrorBoundaryProps = {
  children: React.ReactNode
}

type ErrorBoundaryState = {
  error: Error | null,
  errorInfo: ErrorInfo | null
}

export class PageErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {error: null, errorInfo: null}
  }

  // This method is called if any error is encountered
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and
    // re-render with error message
    this.setState({
      error,
      errorInfo,
    })

    // You can also log error messages to an error
    // reporting service here
    // eslint-disable-next-line no-console
    console.error('Error was caught:', {error, errorInfo})
  }

  // This will render this component wherever called
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className='m-5 p-5 rounded-lg bg-red-500 text-white'>
          <div className='text-lg font-semibold'>An Error Has Occurred</div>
          <details className='mt-5'>
            {this.state.error && <div className='font-semibold mb-3'>{this.state.error.toString()}</div>}
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      )
    }
    // Normally, just render children, i.e. in
    // case no error is Found
    return this.props.children
  }
}
