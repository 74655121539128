import {AxiosRequestConfig} from 'axios'
import {stringify} from 'query-string'

import auth from '../../auth/auth'
import {apiAxios} from "./api-axios";

export type PageResponse<T> = {
  items: T[]
  totalItems: number
  totalPages: number
  pageIndex: number
  pageSize: number
}

export class ApiBase {

  async $getSingle<Type>(endpoint: string, queryParams?: Record<string, any>): Promise<Type> {
    const queryString = stringify(queryParams || {})
    const url = endpoint + (queryString ? `?${queryString}` : '')

    const {data} = await apiAxios.get(url, await this.getConfig())

    return data
  }

  async $getPaged<Type>(path: string, pageIndex: number, pageSize: number, queryParams?: Record<string, any>): Promise<PageResponse<Type>> {
    // Beside standard query param we need to add paging params
    const pagingQueryParams = {
      pageIndex,
      pageSize,
    }

    const queryString = stringify({...(queryParams || {}), ...pagingQueryParams}, {skipEmptyString: true})
    const url = path + (queryString ? `?${queryString}` : '')

    const {data} = await apiAxios.get(url, await this.getConfig())

    return data
  }

  async $put<Type>(path: string, body: Type): Promise<void> {
    const {data} = await apiAxios.put(path, body, await this.getConfig())

    return data
  }

  async $post<Type, ResultType = string>(path: string, body: Type): Promise<ResultType> {
    const {data} = await apiAxios.post(path, body, await this.getConfig())

    return data
  }

  async $delete(path: string): Promise<void> {
    const {data} = await apiAxios.delete(path, await this.getConfig())

    return data
  }

  async $download(path: string): Promise<void> {

    const response = await apiAxios.get(path, {...await this.getConfig(), responseType: 'blob'})

    const contentDisposition = response.headers['content-disposition'] || ''

    const regex = /filename="(.+?)"/;
    const match = regex.exec(contentDisposition);
    const fileName = match ? match[1] : 'downloadedFile';

    const blob = response.data;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName
    link.click();
    window.URL.revokeObjectURL(url);
  }

  async getConfig(): Promise<AxiosRequestConfig> {

    const config: AxiosRequestConfig = {headers: {}}

    if (auth.isLoggedIn()) {

      const callback = () => {
        config.headers!.Authorization = 'Bearer ' + auth.getToken()
        return Promise.resolve(config)
      }

      await auth.updateToken(callback)
    }

    return config
  }

}
