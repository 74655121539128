import {
  Dialog as TrinidadDialog,
  DialogConfirm as TrinidadDialogConfirm,
  DialogConstants as TrinidadDialogConstants
} from 'trinidad-ui'
import PromiseUtils from "./PromiseUtils";
import {ErrorUtils} from "./ErrorUtils";
import i18n from '../i18n/i18n'
import {RetryError} from '../errors/RetryError';

const ENABLE_LOGGER = process.env.NODE_ENV === 'development'

const dialogManager = new TrinidadDialog();

const _log = (text: string, args?: any) => {
  if (ENABLE_LOGGER)
    console.log('DIALOG: ' + text, args)
}

type DialogMessage = {
  type?: 'text' | 'checkbox',
  align?: 'left' | 'center' | 'right',
  text: string,
  value?: any,
  block?: boolean
}

export type Dialog = {
  close: () => void
}

export class DialogUtils {

  /**
   * Display a simple notification dialog.
   * @param icon
   * @param title
   * @param label
   */
  static showNotificationDialog({
                                  icon,
                                  title,
                                  text,
                                  closeLabel,
                                  onClose,
                                  errorMode,
                                  toaster
                                }: {
    icon: string,
    title: string,
    text?: string | null,
    closeLabel: string,
    onClose?: () => void,
    errorMode?: boolean
    toaster?: boolean
  }): Dialog {

    _log('Showing notification dialog')

    const message: DialogMessage[] = DialogUtils.createMessages(text)
    const _dialog = new TrinidadDialogConfirm({
      icon,
      branding: TrinidadDialogConstants.branding.NEUTRAL,
      title,
      message,
      type: errorMode ? 'error' : 'default',
      area: toaster ? TrinidadDialogConstants.area.TOASTER : TrinidadDialogConstants.area.POPUP,
      convertToPopup: false,
      actions: [
        {
          title: closeLabel,
          callback: () => {
            _log('Close click')
            _dialog.close(onClose);
          }
        }
      ]
    });

    dialogManager.add(_dialog)
    return _dialog
  }

  /**
   * Display a confirmation dialog.
   * Confirmation might call another process, sync or async after which an error state might be displayed.
   * @param icon
   * @param title
   * @param text
   * @param confirmLabel
   * @param closeLabel
   * @param onClose
   * @param onConfirm
   * @param onConfirmComplete
   * @param errorCloseLabel
   * @param errorTitle
   * @param toaster
   * @param retryOnError
   */
  static showConfirmationDialog({
                                  icon,
                                  title,
                                  text,
                                  confirmLabel,
                                  closeLabel,
                                  onClose,
                                  onConfirm,
                                  onConfirmComplete,
                                  errorCloseLabel,
                                  errorTitle,
                                  toaster,
                                  retryOnError
                                }: {
    icon: string,
    title: string,
    text?: string | null,
    confirmLabel: string,
    closeLabel: string,
    onClose?: () => void,
    onConfirm: () => void | Promise<any>,
    onConfirmComplete?: (confirmResult: any) => void,
    errorTitle?: string,
    errorCloseLabel?: string,
    toaster?: boolean,
    retryOnError?: boolean
  }): Dialog {

    _log('Showing confirmation dialog')

    const message: DialogMessage[] = DialogUtils.createMessages(text)

    const _dialog = new TrinidadDialogConfirm({
      icon,
      branding: TrinidadDialogConstants.branding.TUR,
      title,
      message,
      area: toaster ? TrinidadDialogConstants.area.TOASTER : TrinidadDialogConstants.area.POPUP,
      convertToPopup: false,
      actions: [
        {
          title: closeLabel,
          callback: () => {
            _log('Close click')
            // When close is clicked, we just close the dialog and, optionally, invoke callback
            if (toaster) {
              _dialog.close();
              if (onClose)
                onClose()
            } else {
              _dialog.close(onClose);
            }
          }
        },
        {
          title: confirmLabel,
          callback: async () => {
            _log('Confirm click')
            // When confirm is clicked, we invoke onConfirm callback
            // If this function returns a promise, we execute the promise and once it's done,
            // we close the dialog and execute the onConfirmComplete callback
            const confirmationResult = onConfirm()

            if (PromiseUtils.isPromise(confirmationResult)) {

              _log('Resolving promise')

              // If confirmation result is a promise, wait for the result
              try {
                const awaitedResult = await confirmationResult

                // Now we can invoke onConfirmationComplete function and close the dialog
                if (onConfirmComplete) {
                  onConfirmComplete(awaitedResult)
                }

                _log('Resolved successfully')

                _dialog.close()
              } catch (error) {

                _log('Error resolving', error)

                let errorMessage: string
                let retryConfirmationTitle = title
                let retryConfirmationText = text

                if (error instanceof RetryError) {
                  const retryError = error as RetryError
                  const originalError = retryError.originalError
                  const errorObject = ErrorUtils.createErrorObject(originalError)

                  errorMessage = ErrorUtils.getErrorMessage(errorObject)

                  errorMessage += '\n' + retryError.errorMessage

                  if(retryError.title) {
                    retryConfirmationTitle = retryError.title
                  }

                  if(retryError.text) {
                    retryConfirmationText = retryError.text
                  }
                } else {
                  const errorObject = ErrorUtils.createErrorObject(error)
                  errorMessage = ErrorUtils.getErrorMessage(errorObject)
                }

                // If an error happens while executing in the dialog, we need to handle that separately
                // First we close this dialog, and then we open an error dialog

                const onErrorFunction = () => {
                  DialogUtils.showNotificationDialog({
                    icon,
                    title: errorTitle || i18n.t('dialogs.error.title'),
                    text: errorMessage,
                    closeLabel: errorCloseLabel || i18n.t('dialogs.error.closeLabel'),
                    errorMode: true,
                    onClose: () => {
                      // When closing error dialog, we have an option to reopen confirmation dialog again
                      if (retryOnError) {
                        DialogUtils.showConfirmationDialog({
                          retryOnError: true,
                          icon,
                          onClose,
                          onConfirm,
                          onConfirmComplete,
                          errorCloseLabel,
                          errorTitle,
                          toaster,
                          closeLabel,
                          confirmLabel,
                          text: retryConfirmationText,
                          title: retryConfirmationTitle
                        })
                      } else {
                        if (onClose)
                          onClose()
                      }
                    }
                  })
                }

                // Closing toaster behaves somewhat differently when closing it so we handle it differently
                if (toaster) {

                  _dialog.close()

                  onErrorFunction()

                } else {
                  _dialog.close(() => {
                    _log('Dialog closed', error)

                    onErrorFunction()
                  })
                }
              }

            } else {
              // If confirmation result is not a promise, just close the dialog
              _dialog.close();
            }

          },
          // Here we make an assumption that if we provide onConfirmComplete callback, we want to display loader
          showLoader: !!onConfirmComplete
        }
      ]
    });

    dialogManager.add(_dialog)

    return _dialog
  }

  /**
   * Convert text into an array of dialog message object.
   * This function will try to break a single text into multiple messages using new line separator within text.
   * @param text
   * @private
   */
  private static createMessages(text?: string | null): DialogMessage[] {

    if (!text)
      return []

    const messages: DialogMessage[] = []

    const textParts = text.split("\n")

    textParts.forEach(part => {
      if (part)
        messages.push({text: part, align: 'center'})
    })

    return messages
  }

}
