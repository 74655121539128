import {useTranslationResult} from "../../hooks/useTranslationResult";
import React, {useCallback} from "react";
import {
  ControlGroup as TrinidadControlGroup,
  DropdownItem as TrinidadDropdownItem,
  DropdownLabel as TrinidadDropdownLabel,
  DropdownSelectItem as TrinidadSelectItem
} from 'trinidad-ui'

export type SelectBoxOption = {
  value: any
  name: string
  data?: any
  icon?: React.ReactNode
}

export type SelectBoxProps = {
  options: any[]
  selectedOption: any
  onChange: (value: SelectBoxOption) => void
  name?: string
  label?: string | null
  labelKey?: string
  className?: string
  defaultValue?: any
  required?: boolean
  placeholder?: string | null
  placeholderKey?: string
  errorMessage?: string
  [key: string]: any
}

const SelectBox = ({
                     options,
                     selectedOption,
                     onChange,
                     name,
                     label,
                     labelKey,
                     errorMessage,
                     placeholder,
                     placeholderKey,
                     className,
                     ...rest
                   }: SelectBoxProps) => {

  const labelResult = useTranslationResult(label, labelKey)
  const placeholderResult = useTranslationResult(placeholder, placeholderKey)

  const onChangeWrapper = useCallback((value: string) => {
    if (onChange) {
      // We get a value, but we want to dispatch option object
      const option = options.find(it => it.value === value)
      onChange(option)
    }
  }, [onChange, options])

  return (
    <TrinidadControlGroup className={className}>
      <TrinidadSelectItem
        onSelect={onChangeWrapper}
        selectLabel={labelResult}
        v2
        defaultText={placeholderResult}>
        {options.map(option => {
          return (
            <TrinidadDropdownItem
              key={option.value}
              name={option.value}
              selected={selectedOption?.value === option.value}
            >
              {option.icon}
              <div className="truncate">
                <TrinidadDropdownLabel>
                  {option.name}
                </TrinidadDropdownLabel>
              </div>
            </TrinidadDropdownItem>
          )
        })}
      </TrinidadSelectItem>
    </TrinidadControlGroup>
  )
}

export default SelectBox
