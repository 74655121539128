import React, {useCallback} from 'react'

import {Dialog} from "../dialogs/Types";
import {useAppDispatch} from "../../store/hooks";
import {closeDialog, errorDialog, startDialog} from "../../redux/app-dialogs";
import PromiseUtils from "../../utils/PromiseUtils";
import {ErrorUtils} from "../../utils/ErrorUtils";
import {ConfirmationDialog} from "../dialogs/ConfirmationDialog";
import {NotificationDialog} from "../dialogs/NotificationDialog";

type AppDialogComponentProps = {
  dialog: Dialog
}

export const AppDialogComponent = ({dialog}: AppDialogComponentProps) => {
  const dispatch = useAppDispatch()

  /**
   * When closing a dialog, invoke a callback method if it's provided and remove the dialog.
   */
  const onCloseWrapper = useCallback(() => {
    if (dialog.onClose) {
      dialog.onClose(dialog)
    }
    dispatch(closeDialog(dialog))
  }, [dispatch, dialog])

  /**
   * When confirming a dialog, invoke a callback method and check if a promise is returned that
   * requires further managing.
   * If a promise is returned that a start/try/catch flow is executed, otherwise dialog is removed.
   */
  const onConfirmWrapper = useCallback(async () => {
    const result = dialog.onConfirm ? dialog.onConfirm(dialog) : null

    if (PromiseUtils.isPromise(result)) {
      try {
        dispatch(startDialog(dialog))

        const awaitedResult = await result

        if (dialog.onConfirmComplete) {
          dialog.onConfirmComplete(dialog, awaitedResult)
        }

        dispatch(closeDialog(dialog))
      } catch (error) {
        dispatch(errorDialog({dialog, error: ErrorUtils.createErrorObject(error)}))
      }
    } else {
      dispatch(closeDialog(dialog))
    }
  }, [dispatch, dialog])

  // Since we have different controls for confirmation and notification dialogs, we differ them
  // by the options that the model provides

  if (dialog.closeLabel) {
    return (
      <ConfirmationDialog
        text={dialog.text}
        secondaryText={dialog.secondaryText}
        visible={dialog.visible}
        confirmLabel={dialog.confirmLabel}
        closeLabel={dialog.closeLabel}
        error={dialog.error}
        onClose={onCloseWrapper}
        onConfirm={onConfirmWrapper}
        saving={dialog.saving}
        icon={dialog.icon}
        iconSize={dialog.iconSize}
        iconClassName={dialog.iconClassName}
        title={dialog.title}
      />
    )
  } else {
    return (
      <NotificationDialog
        text={dialog.text}
        secondaryText={dialog.secondaryText}
        closeLabel={dialog.confirmLabel}
        onClose={onCloseWrapper}
        visible={dialog.visible}
        icon={dialog.icon}
        title={dialog.title}
        iconSize={dialog.iconSize}
        iconClassName={dialog.iconClassName}
      />
    )
  }
}
