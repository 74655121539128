import {createSlice} from '@reduxjs/toolkit'
import {ErrorObject} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";

interface AssociationPageState {
  isLoading: boolean
  loadingError: ErrorObject | null
}

const initialState: AssociationPageState = {
  isLoading: false,
  loadingError: null,

}

export const associationSlice = createSlice({
  name: 'association',
  initialState,
  reducers: {
    initPage: (state) => {
      // TODO
    },
  },
  extraReducers: (builder) => {

  },
})

// Actions
export const {initPage} = associationSlice.actions

// Selectors
export const selectIsLoading = (state: RootState) => state.association.isLoading
export const selectLoadingError = (state: RootState) => state.association.loadingError

export default associationSlice.reducer
