import React, {useEffect, useRef} from 'react'
import {Loader as TrinidadLoader} from 'trinidad-ui';

type LoaderProps = {
  className?: string,
}

export const Loader = ({className}: LoaderProps) => {

  const ref = useRef(null)

  useEffect(() => {
    // Here we are showing the loader
    const myLoader = new TrinidadLoader(ref.current);

    // Size support for: 100, 200, 300
    myLoader.show(300, 'inverted');

    return () => {
      // Here we are removing the loader
      myLoader.hide();
    }
  }, [])

  return (
    <div className={`loader-placeholder flex justify-center items-center ${className}`} ref={ref}>
    </div>
  )
}
