import {ApiBase, PageResponse} from './core/api-base'
import {Price, UserActionDTO} from "../utils/AssociationTypes";
import moment from "moment";

export interface AssociationBrandingDTO {
  logo?: string
  background?: string
  color?: string
  secondColor?: string
}

export interface AssociationBankDetailsDTO {
  accountNumber?: string
}

export interface AssociationNoteDTO {
  id?: string // id is generated on front end (not used at all on back end)
  description?: string
  created?: UserActionDTO
}

export interface AssociationContactDTO {
  id?: string // id is generated on front end (not used at all on back end)
  name?: string
  role?: string
  email?: string
  phone?: string
}

export interface AssociationAddressDTO {
  street?: string
  zip?: string
  city?: string
}

export interface AssociationDTO {
  id: string
  name: string
  shortName?: string
  partnerId: number
  organisationNumber?: string
  branding?: AssociationBrandingDTO
  bankDetails?: AssociationBankDetailsDTO
  notes?: AssociationNoteDTO[]
  contacts?: AssociationContactDTO[]
  isDisabled?: boolean
  created?: UserActionDTO
  updated?: UserActionDTO
  address?: AssociationAddressDTO
}

export type ReportPeriodType = 'HOURLY' | 'DAILY' | 'MONTHLY' | 'UNKNOWN_PERIOD'

export interface AssociationDrawStatsDTO {
  poolContribution: Price
  buy: Price
  associationContribution: Price
  numberOfRows: number
  numberOfTickets: number
  numberOfDraws: number
  numberOfUsers: number
}

export interface AssociationDrawStatsReport {
  periodType: ReportPeriodType
  time: string
  associationId: string
  stats: AssociationDrawStatsDTO
  summary: AssociationDrawStatsDTO
}

export interface MonthlyReport {
  name: string
  type: string
  year: number
  month: number
}

class AssociationsApi extends ApiBase {

  async getAssociations(pageIndex: number, pageSize: number): Promise<PageResponse<AssociationDTO>> {
    return this.$getPaged<AssociationDTO>('/associations', pageIndex, pageSize)
  }

  async getAssociation(userId: string) {
    return this.$getSingle('/associations/' + userId)
  }

  public getDrawStats(associationId: string, periodType: ReportPeriodType, teamId: string | undefined, fromIsoDate: string, toIsoDate?: string): Promise<AssociationDrawStatsReport[]> {
    const zoneOffset = moment().format('Z')
    return this.$getSingle('/associations/' + associationId + '/draw-stats-report', { from: fromIsoDate, to: toIsoDate, periodType, teamId, zoneOffset })
  }

  public downloadMonthlyReport(associationId: string, yearMonth: string): Promise<void> {
    const fileName = yearMonth + '.pdf'
    return this.$download('/' + associationId + '/reports/monthlyAssociationReport/' + fileName)
  }

  public listMonthlyReports(associationId: string): Promise<MonthlyReport[]> {
    return this.$getSingle(`/${associationId}/reports/list/monthly/monthlyAssociationReport`)
  }

}

export const associationsApi = new AssociationsApi()
