import React from 'react'
import {FaCheckDouble, FaFire, FaRegHourglass, FaStopwatch, FaUsersCog,} from 'react-icons/fa'

import {Icon as TrinidadIcon} from 'trinidad-ui'
import {PartiallyOptional} from "../../utils/AssociationTypes";

export type IconSize = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 950 | 1000 | 1100

export type IconProps = {
  size: IconSize,
  color?: string
  className?: string
  onClick?: () => void
}

export const ClockIcon = (props: IconProps) => <TrinidadIcon icon="clock-simple" {...props}/>
export const CheckIcon = (props: IconProps) => <TrinidadIcon icon="checkmark" {...props}/>
export const CoinsIcon = (props: IconProps) => <TrinidadIcon icon="money" {...props}/>
export const PlayersIcon = (props: IconProps) => <TrinidadIcon icon="players" {...props}/>
export const TicketsIcon = (props: IconProps) => <TrinidadIcon icon="games" {...props}/>
export const CalendarIcon = (props: IconProps) => <TrinidadIcon icon="calendar" {...props}/>
export const UserIcon = (props: IconProps) => <TrinidadIcon icon="user" {...props}/>
export const LoginIcon = (props: IconProps) => <TrinidadIcon icon="login" {...props}/>
export const FlagsIcon = (props: IconProps) => <TrinidadIcon icon="flag" {...props}/>
export const DrawsIcon = (props: IconProps) => <FaCheckDouble {...props}/>

export const StatisticsIcon = (props: IconProps) => <TrinidadIcon icon='statistics' {...props}/>
export const AssociationIcon = (props: IconProps) => <TrinidadIcon icon='arena' {...props}/>
export const SettingsIcon = (props: IconProps) => <TrinidadIcon icon='settings' {...props}/>
export const AdminIcon = (props: IconProps) => <FaUsersCog {...props}/>
export const ChevronDownIcon = (props: IconProps) => <TrinidadIcon icon='menu-down' {...props}/>
export const InformationIcon = (props: IconProps) => <TrinidadIcon icon='information' {...props}/>
export const InformationFilledIcon = (props: IconProps) => <TrinidadIcon icon='information' {...props}/>
export const FireIcon = (props: IconProps) => <FaFire {...props}/>
export const RemainingTimeIcon = (props: IconProps) => <FaStopwatch {...props}/>
export const PendingIcon = (props: IconProps) => <FaRegHourglass {...props}/>
export const MessageIcon = (props: IconProps) => <TrinidadIcon icon='chat' {...props}/>
export const PhoneIcon = (props: IconProps) => <TrinidadIcon icon='mobile' {...props}/>
export const DeleteIcon = (props: IconProps) => <TrinidadIcon icon='remove-sign' {...props}/>
export const ThumbsUpIcon = (props: IconProps) => <TrinidadIcon icon='thumbs-up' {...props}/>
export const TeamIcon = (props: IconProps) => <TrinidadIcon icon='team' {...props}/>
export const MenuIcon = (props: IconProps) => <TrinidadIcon icon='menu' {...props}/>
export const DocumentIcon = (props: IconProps) => <TrinidadIcon icon='document' {...props}/>

export const ChevronUp = (props: IconProps) => <TrinidadIcon icon='menu-up' {...props}/>

export const ChevronDown = (props: IconProps) => <TrinidadIcon icon='menu-down' {...props}/>

export const ExclamationIcon = (props: IconProps) => <TrinidadIcon icon='exclamation-sign' {...props}/>

export const GenericIcon = (props: PartiallyOptional<IconProps, 'size'> & { icon: string }) =>
  <TrinidadIcon {...props}/>

