import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";
import {AdministratorsModel, createModel} from "./model";
import {AdministratorDTO, administratorsApi} from "../../../apis/administrators-api";
import {AssociationDTO} from "../../../apis/associations-api";
import {TeamDTO} from "../../../apis/teams-api";
import {PageResponse} from "../../../apis/core/api-base";

interface AdministratorsState {
  isLoading: boolean
  loadingError: ErrorObject | null,
  model: AdministratorsModel | null
}

const initialState: AdministratorsState = {
  isLoading: false,
  loadingError: null,
  model: null
}

export const fetchData = createAsyncThunk<[TeamDTO, AssociationDTO, PageResponse<AdministratorDTO>]>(
  'administrators/fetchData',
  async (payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState
    return Promise.all([
      rootState.settings.selectedTeam!,
      rootState.settings.selectedAssociation!,
      administratorsApi.getAdministrators(0, 10_000, {associationId: rootState.settings.selectedAssociation!.id})
    ])
  },
)

export const administratorsSlice = createSlice({
  name: 'administrators',
  initialState,
  reducers: {
    initPage: () => {

    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      const [team, association, administratorsPage] = action.payload
      state.isLoading = false
      state.model = createModel(administratorsPage.items, association, team)
    })

    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

  },
})

// Actions
export const {initPage} = administratorsSlice.actions

// Selectors
export const selectIsLoading = (state: RootState) => state.administrators.isLoading
export const selectLoadingError = (state: RootState) => state.administrators.loadingError
export const selectModel = (state: RootState) => state.administrators.model

export default administratorsSlice.reducer
