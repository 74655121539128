import {AdministratorAssociationStatus, AdministratorDTO} from "../../../apis/administrators-api";
import {AssociationDTO} from "../../../apis/associations-api";
import {TeamDTO} from "../../../apis/teams-api";
import {AdministratorUtils} from "../../../utils/AdministratorUtils";

export type AdministratorsAddModel = {
  id?: string
  email?: string
  isChairman?: boolean
  firstName?: string
  lastName?: string
  phone?: string
  status?: AdministratorAssociationStatus
  administratorAssociationId?: string
  createdDate?: string
  statusDate?: string
  associationId: string
  associationName: string
  personalNumber?: string
  dto?: AdministratorDTO
  isPendingExpired?: boolean
}

export const createModel = (administrator: AdministratorDTO | undefined, currentAssociation: AssociationDTO, _currentTeam: TeamDTO): AdministratorsAddModel => {

  const findCurrentAdministratorAssociation = (administrator: AdministratorDTO) => {
    return administrator.associations!.find(association => association.association!.id === currentAssociation.id)!
  }

  if (!administrator) {
    return {
      associationId: currentAssociation.id,
      associationName: currentAssociation.name
    }
  } else {

    const administratorAssociation = findCurrentAdministratorAssociation(administrator)

    return {
      dto: administrator,
      id: administrator.id,
      email: AdministratorUtils.getTemporaryOrRealEmail(administrator, administratorAssociation),
      isChairman: administratorAssociation.roles.includes('CHAIRMAN'),
      administratorAssociationId: administratorAssociation.id,
      firstName: AdministratorUtils.getTemporaryOrRealFirstName(administrator, administratorAssociation),
      lastName: AdministratorUtils.getTemporaryOrRealLastName(administrator, administratorAssociation),
      phone: AdministratorUtils.getTemporaryOrRealPhone(administrator, administratorAssociation),
      status: administratorAssociation.status,
      createdDate: administratorAssociation.created?.time,
      statusDate: administratorAssociation.statusDate,
      associationId: currentAssociation.id,
      associationName: administratorAssociation.association.name,
      personalNumber: administrator.personalNumber,
      isPendingExpired: AdministratorUtils.isAdministratorPendingExpired(administratorAssociation)
    }
  }
}
