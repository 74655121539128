import {Page} from "../../components/page/Page";
import React from "react";
import {useTranslation} from "react-i18next";

export const RouteNotFoundPage = () => {

  const {t} = useTranslation()

  return (
    <Page titleKey='pages.routeNotFound.title'>
      <div className='text-center'>
        <div className='text-purple-light font-bold text-3xl mt-32'>404</div>
        <div className='text-neutral-light mt-12'>{t('pages.routeNotFound.description')}</div>
      </div>
    </Page>
  )
}

