import {PayloadAction} from "@reduxjs/toolkit";
import i18n from "../i18n/i18n";
import {FormattingUtils} from "./FormattingUtils";

export type ConstraintError = {
  code: string
  field: string
  message: string
}

export type ErrorObject = {
  name: string
  code?: string
  message?: string
  args?: Record<string, any>
  errors?: ConstraintError[]
}

export class ErrorUtils {

  static getErrorMessage(error: ErrorObject | string): string {
    if (!error) return 'Error'

    if (typeof error === 'string')
      return error

    const code = error.code
    const key = 'errors.' + code

    // We might need to do some arguments updates
    if (error.args) {
      if (error.args.salesStart) error.args.salesStart = FormattingUtils.formatDateTime(error.args.salesStart)
      if (error.args.salesEnd) error.args.salesEnd = FormattingUtils.formatDateTime(error.args.salesEnd)
    }

    let message

    if (i18n.exists(key)) {
      message = i18n.t(key, error.args) || ''
    } else if (error.message) {
      message = error.message
    } else if (code) {
      message = code
    } else {
      message = error.toString()
    }

    // We might have some constraint errors also, if so, we want to append them to the message
    const constraintMessage = this.getConstraintMessage(error.errors)

    if (constraintMessage)
      message += ' ' + constraintMessage

    return message
  }

  static createErrorObjectFromAction(action: PayloadAction<any, string, any, any>, defaultErrorMessage?: string): ErrorObject {

    return this.createErrorObject(action.error || action.payload, defaultErrorMessage)
  }

  static createErrorObject(error: any, defaultErrorMessage?: string): ErrorObject {

    let code
    let message
    let args
    let name
    let errors: ConstraintError[] | undefined

    if (error) {
      name = error.name

      if (error.name === 'AxiosError') {
        if (error.stack) {
          try {
            const stackObject = JSON.parse(error.stack)

            if (typeof stackObject === 'object') {
              code = stackObject.code
              message = stackObject.message
              args = stackObject.args || stackObject.data

              if (stackObject.data && stackObject.data.GRPC_CODE) {
                code = stackObject.data.GRPC_CODE
              }

              if (stackObject.errors) {
                errors = this.extractConstraintErrors(stackObject.errors)
              }

            } else {
              message = stackObject
            }
          } catch (e) {

          }
        } else {
          code = error.code
          message = error.message
        }
      }

      if (!message) {
        message = defaultErrorMessage
      }

    }

    return {
      code,
      message,
      args,
      name,
      errors
    }
  }

  private static extractConstraintErrors(errors: any[]): ConstraintError[] | undefined {

    if (!errors || !Array.isArray(errors) || errors.length === 0)
      return

    return errors.map(it => ({
      code: it.code,
      message: it.message,
      field: it.field
    }))
  }

  private static getConstraintMessage(errors: ConstraintError[] | undefined): string | undefined {

    if (!errors || errors.length === 0)
      return

    // Here we only display unique messages
    const allMessages: string[] = errors.map(it => {

      const code = it.code
      const key = 'constraintErrors.' + code

      let message

      if (i18n.exists(key)) {
        message = i18n.t(key)
      } else if (it.message) {
        message = it.message
      } else if (code) {
        message = code
      } else {
        message = it.toString()
      }

      return message
    })

    const uniqueMessages: string[] = Array.from(new Set(allMessages))

    return uniqueMessages.join(', ')
  }
}
