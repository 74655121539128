import React from 'react'

import {useAppSelector} from "../../store/hooks";
import {selectDialogs} from "../../redux/app-dialogs";
import {AppDialogComponent} from "./AppDialogComponent";

/**
 * Component that manages display of globally available dialog windows.
 * It uses its own redux store for managing dialog models throughout the app.
 */
export const AppDialogsComponent = () => {
  const dialogs = useAppSelector(selectDialogs)

  if (!dialogs.length) { return null }

  return (
    <>
      {dialogs.map(it => <AppDialogComponent key={it.id} dialog={it} />)}
    </>
  )
}
