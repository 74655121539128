import moment, {Moment} from "moment/moment"

export type WeekOfMonth = {
  weekInMonth: number
  weekInYear: number
  firstDate: number
  lastDate: number
}

export type DayOfWeek = {
  dayOfMonth: number
  dayOfWeek: number
  dayOfYear: number
  year: number
}

export type PeriodType = 'SEASON' | 'YEAR' | 'MONTH' | 'WEEK' | 'DAY'

export class DateUtils {

  static getWeeksOfMonth(year: number, month: number): WeekOfMonth[] {

    const startDate = moment.utc([year, month])

    // Get the first and last day of the month
    const firstDay = moment(startDate).startOf('month')
    const endDay = moment(startDate).endOf('month')


    // Get all the weeks during the current month
    const weeks = []
    const iterationDay = firstDay.clone()

    while (true) {
      if (iterationDay.isAfter(endDay))
        break

      if (weeks.indexOf(iterationDay.week()) === -1) {
        weeks.push(iterationDay.week());
      }

      iterationDay.add(1, 'day')
    }

    // Create a range for each week
    const calendar: WeekOfMonth[] = []

    for (let index = 0; index < weeks.length; index++) {
      let weekNumber = weeks[index];

      // If we are in December, the last week might be numbered as the first week of next year e.g. [49, 50, 51, 52, 1]
      // We need to make sure it's one week 'more' than its previous week number
      if (month === 11 && (weeks.length - 1) === index) {
        weekNumber = weeks[index - 1] + 1
      }

      let firstWeekDay = moment().year(year).week(weekNumber).weekday(0);
      let lastWeekDay = moment().year(year).week(weekNumber).weekday(6);

      if (firstWeekDay.isBefore(firstDay)) {
        firstWeekDay = firstDay;
      }

      if (lastWeekDay.isAfter(endDay)) {
        lastWeekDay = endDay;
      }

      calendar.push({
        weekInMonth: index,
        weekInYear: weekNumber,
        firstDate: firstWeekDay.date(),
        lastDate: lastWeekDay.date()
      })
    }

    return calendar
  }

  static getDaysOfWeek(year: number, week: number): DayOfWeek[] {

    const startDate = moment().year(year).week(week)

    // Get the first and last day of the week
    const firstDay = moment(startDate).startOf('week')
    const endDay = moment(startDate).endOf('week')


    // Get all the days during the current week
    const days: DayOfWeek[] = []
    const iterationDay = firstDay

    while (true) {
      if (iterationDay.isAfter(endDay))
        break

      days.push({
        dayOfMonth: iterationDay.date(),
        dayOfWeek: iterationDay.day(),
        dayOfYear: iterationDay.dayOfYear(),
        year: iterationDay.year()
      });

      iterationDay.add(1, 'day')
    }

    return days
  }

  static inputDateTimeToUtc(dateString: string | undefined, inputType: 'datetime-local' | 'date' | 'time'): string | undefined {
    if (!dateString) {
      return
    }

    if (inputType === 'date') {
      return dateString
    }

    return moment(dateString, 'YYYY-MM-DDTHH:mm').utc().toISOString()
  }

  static utcToInputDateTime(dateString: string | undefined, inputType: 'datetime-local' | 'date' | 'time'): string | undefined {
    if (!dateString) {
      return
    }

    if (inputType === 'date') {
      return dateString
    }

    return moment(dateString).format('YYYY-MM-DDTHH:mm')
  }

  static createFromToIso(selectedPeriodType: PeriodType, selectedPeriodValue: string, selectedDay: string | undefined, startMonth: number): [string, string] {

    let startMoment: Moment | undefined, endMoment: Moment | undefined

    if (selectedPeriodType === 'SEASON') {
      // We chose season start year
      const seasonStartMonth = startMonth - 1
      const year = parseFloat(selectedPeriodValue)

      const anyDateInMonthMoment = moment().set('year', year).set('month', seasonStartMonth)

      startMoment = anyDateInMonthMoment.startOf('month')
      endMoment = startMoment.clone().add(1, 'year')

    } else if (selectedPeriodType === 'YEAR') {
      // We chose season start year
      const year = parseFloat(selectedPeriodValue)

      const anyDateInMonthMoment = moment().set('year', year).set('month', 0)

      startMoment = anyDateInMonthMoment.startOf('month')
      endMoment = startMoment.clone().add(1, 'year')

    } else if (selectedPeriodType === 'MONTH') {
      // We chose year-month
      const parts = selectedPeriodValue.split('-')
      const year = parseFloat(parts[0])
      const month = parseFloat(parts[1])

      const anyDateInMonthMoment = moment().set('year', year).set('month', month)

      startMoment = anyDateInMonthMoment.clone().startOf('month')
      endMoment = anyDateInMonthMoment.clone().endOf('month')

    } else if (selectedPeriodType === 'WEEK') {
      // We chose year-week
      const parts = selectedPeriodValue.split('-')
      const year = parseFloat(parts[0])
      const week = parseFloat(parts[1])

      const anyDateInWeekMoment = moment().set('year', year).set('week', week)

      startMoment = anyDateInWeekMoment.clone().startOf('week')
      endMoment = anyDateInWeekMoment.clone().endOf('week')

    } else if (selectedPeriodType === 'DAY') {

      const selectedDayMoment = moment(selectedDay)

      startMoment = selectedDayMoment.clone().startOf('day')
      endMoment = selectedDayMoment.clone().endOf('day')
    }

    if (!startMoment || !endMoment)
      throw new Error('Both start and date must be defined for any period.')

    return [startMoment.toISOString(), endMoment.toISOString()]
  }

  static getSeasonStartYear(teamSeasonStartMonth: number): number {
    if (!teamSeasonStartMonth)
      teamSeasonStartMonth = 1

    const now = new Date()
    const hasSeasonStarted = now.getMonth() >= (teamSeasonStartMonth - 1)

    return now.getFullYear() - (hasSeasonStarted ? 0 : 1)
  }

  static getCountdownStrings(date?: string | Date): [string, string, string, string] {

    let days = 0
    let hours = 0
    let minutes = 0
    let seconds = 0

    if (date) {

      const drawingTimeMoment = moment(date)
      const currentMoment = moment()

      if (drawingTimeMoment.isAfter(currentMoment)) {

        const duration = moment.duration(drawingTimeMoment.diff(currentMoment))
        days = Math.min(Math.floor(duration.asDays()), 99);
        hours = duration.hours()
        minutes = duration.minutes();
        seconds = duration.seconds();
      }
    }

    return [
      (days + '').padStart(2, '0'),
      (hours + '').padStart(2, '0'),
      (minutes + '').padStart(2, '0'),
      (seconds + '').padStart(2, '0')
    ]
  }

}
