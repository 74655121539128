import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";
import {MarketingMaterialsType, teamsApi} from "../../../apis/teams-api";
import {administratorsApi} from "../../../apis/administrators-api";
import {DialogUtils} from "../../../utils/DialogUtils";
import i18n from "../../../i18n/i18n";
import {setConfirmedMarketing} from "../../../redux/settings";

type MarketingMaterialDownloadStatus = {
  isDownloading: boolean
  error: ErrorObject | null
}

interface MarketingPageState {
  materialStatuses: Record<MarketingMaterialsType, MarketingMaterialDownloadStatus>
  isAcceptingTerms: boolean
}

const initialState: MarketingPageState = {
  materialStatuses: {
    print_lg_pdf: {isDownloading: false, error: null},
    print_md_img: {isDownloading: false, error: null}
  },
  isAcceptingTerms: false
}

export const downloadMaterial = createAsyncThunk<void, MarketingMaterialsType>(
  'marketing/downloadMaterial',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState
    const state = rootState.marketing
    const settings = rootState.settings
    const teamId = settings.selectedTeam!.id

    // We don't allow downloading the same thing multiple times in parallel
    if (state.materialStatuses[payload].isDownloading)
      return

    try {

      thunkApi.dispatch(setStatus({materialType: payload, error: null, isDownloading: true}))

      await teamsApi.downloadMaterials(teamId, payload)

      thunkApi.dispatch(setStatus({materialType: payload, error: null, isDownloading: false}))

    } catch (error) {

      const errorObject = ErrorUtils.createErrorObject(error)

      thunkApi.dispatch(setStatus({materialType: payload, error: errorObject, isDownloading: false}))
    }
  },
)

export const acceptTerms = createAsyncThunk<void, boolean>(
  'marketing/acceptTerms',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState
    const settings = rootState.settings

    const selectedAssociation = settings.selectedAssociation!
    const user = settings.user

    const selectedAdministratorAssociation = user!.associations!.find(it => it.association.id === selectedAssociation.id)

    try {

      thunkApi.dispatch(setIsAcceptingMarketingStatus(true))

      await administratorsApi.confirmMarketing(selectedAdministratorAssociation!.id!, payload)

      // Update global state
      thunkApi.dispatch(setConfirmedMarketing({
        administratorAssociationId: selectedAdministratorAssociation!.id!,
        value: payload
      }))

      // Update local flag
      thunkApi.dispatch(setIsAcceptingMarketingStatus(false))

    } catch (error) {

      thunkApi.dispatch(setIsAcceptingMarketingStatus(false))

      const errorObject = ErrorUtils.createErrorObject(error)
      const errorMessage = ErrorUtils.getErrorMessage(errorObject)
      DialogUtils.showNotificationDialog({
        title: i18n.t('pages.marketing.materials.acceptTermsErrorDialog.errorTitle'),
        text: i18n.t('pages.marketing.materials.acceptTermsErrorDialog.errorText', {error: errorMessage}),
        icon: 'exclamation-sign',
        closeLabel: i18n.t('labels.ok'),
        errorMode: true,
      })
    }
  }
)

export const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    initPage: (state) => {
      // TODO
    },
    setStatus: (state, action: PayloadAction<{ materialType: MarketingMaterialsType, isDownloading: boolean, error: ErrorObject | null }>) => {
      state.materialStatuses[action.payload.materialType].isDownloading = action.payload.isDownloading
      state.materialStatuses[action.payload.materialType].error = action.payload.error
    },
    setIsAcceptingMarketingStatus: (state, action: PayloadAction<boolean>) => {
      state.isAcceptingTerms = action.payload
    }
  },
})

// Actions
export const {initPage, setStatus, setIsAcceptingMarketingStatus} = marketingSlice.actions

// Selectors
export const selectMaterialStatuses = (state: RootState) => state.marketing.materialStatuses
export const selectIsAcceptingTerms = (state: RootState) => state.marketing.isAcceptingTerms

export default marketingSlice.reducer
