import React from 'react'
import {IconButton as TrinidadIconButton} from 'trinidad-ui'
import {GenericIcon, IconSize} from "../icons/Icons";

type IconButtonProps = {
  className?: string
  icon: string
  onClick: () => void
  size: IconSize
  color: string
}

export const IconButton = ({icon, className, onClick, size, color}: IconButtonProps) => {
  return (
    <TrinidadIconButton onClick={onClick} className={className}>
      <GenericIcon size={size} icon={icon} color={color}/>
    </TrinidadIconButton>
  )
}
