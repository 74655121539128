import React, {useCallback} from 'react'
import {Drawer as TrinidadDrawer} from 'trinidad-ui'
import {useSelector} from "react-redux";
import {
  selectIsMenuMinimized,
  setMenuMinimized,
  selectIsLoggedIn,
  selectIsFrozenScreenRoute
} from "../../redux/settings";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {NavLink} from "react-router-dom";
import {GenericIcon} from "../icons/Icons";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {Subtitle} from "../text/Text";
export const AppMenu = () => {

  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isOpen = !useSelector(selectIsMenuMinimized)
  const isFrozenScreen = useAppSelector(selectIsFrozenScreenRoute)
  const isDimmerDisabled = false
  const autoFold = true

  const onDimmerClick = useCallback(() => {
    dispatch(setMenuMinimized(true))
    console.log('DIMMER CLICK')
  }, [dispatch])

  // If we are on a frozen screen route, we don't display any menu
  if(isFrozenScreen)
    return null

  return (
    <TrinidadDrawer
      className={clsx('bg-white text-navy border-r border-grey-300', '!w-[19em]')}
      position='left'
      isOpen={isOpen}
      onDimmerClicked={onDimmerClick}
      isDimmerDisabled={isDimmerDisabled}
      autoFold={autoFold}
    >

      <div className='pt-9 xl:pt-24'>
        <Subtitle className='mb-9 pl-6 xl:hidden' textKey='menu.title'/>
        {isLoggedIn && <LocalMenuItem titleKey='menu.start' to='/' icon='nav-home'/>}
        {!isLoggedIn && <LocalMenuItem titleKey='menu.start' to='/signup' icon='nav-home'/>}
        <LocalMenuItem titleKey='menu.getStarted' to='/get-started' icon='spelguide'/>
        {isLoggedIn && <LocalMenuItem matchEnd={true} titleKey='menu.allGames' to='/draws' icon='overview'/>}
        {isLoggedIn && <LocalMenuItem titleKey='menu.createDraw' to='/draws/add' icon='games'/>}
        {isLoggedIn && <LocalMenuItem titleKey='menu.statistics' to='/statistics' icon='statistics'/>}
        {isLoggedIn && <LocalMenuItem titleKey='menu.associations' to='/association' icon='team'/>}
        {isLoggedIn && <LocalMenuItem titleKey='menu.marketing' to='/marketing' icon='content'/>}
        {isLoggedIn && <LocalMenuItem titleKey='menu.help' to='/help' icon='help'/>}
        {isLoggedIn && <LocalMenuItem titleKey='menu.terms' to='/terms-and-privacy' icon='terms-conditions'/>}
      </div>

    </TrinidadDrawer>
  )
}

type LocalMenuItemProps = {
  titleKey: string
  to: string
  icon: string,
  matchEnd?: boolean
}

const LocalMenuItem = ({titleKey, to, icon, matchEnd = false}: LocalMenuItemProps) => {
  const dispatch = useAppDispatch()

  const onLinkClick = useCallback(() => {
    dispatch(setMenuMinimized(true))
  }, [dispatch])

  const {t} = useTranslation()

  return (
    <NavLink end={matchEnd} className="h-12 flex items-center tracking-[.3px] pl-6 relative" to={to} onClick={onLinkClick}>
      {({ isActive }) => (
        <>
          {isActive && <div className="h-full w-1 absolute left-0 bg-red rounded-r-xl"></div>}
          <GenericIcon size={300} icon={icon} color='white'/>
          <span className={clsx('text-[18px] ml-3', isActive && 'font-medium')} >{t(titleKey)}</span>
        </>
      )}
    </NavLink>
  )
}
