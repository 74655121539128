import { TextSize } from "./Text";

const REGULAR_FONT_SIZES = {
  'xs': 'text-xs',
  'sm': 'text-sm',
  'md': 'text-md',
  'xl': 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
}

const MIXED_FONT_SIZES = {
  'xs': 'text-xs sm:text-desktop-xs',
  'sm': 'text-sm sm:text-desktop-sm',
  'md': 'text-md sm:text-desktop-md',
  'xl': 'text-xl sm:text-desktop-xl',
  '2xl': 'text-2xl sm:text-desktop-2xl',
  '3xl': 'text-3xl sm:text-desktop-3xl',
}


export const buildClasses = (size: TextSize, consistent?: boolean) => {
  return consistent ? REGULAR_FONT_SIZES[size] : MIXED_FONT_SIZES[size]
}
