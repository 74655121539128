import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";
import {ConfirmInvitationPageModel, createModel} from "./model";
import {
  AdministratorDTO,
  AdministratorInvitationProtectedDTO,
  administratorsApi
} from "../../../apis/administrators-api";

export type ConfirmInvitationFormData = {
  personalNumber: string
  firstName: string
  lastName: string
  phone: string
  email: string
}


interface ConfirmInvitationState {
  isLoading: boolean
  loadingError: ErrorObject | null,
  token: string | null,
  model: ConfirmInvitationPageModel | null
  isSaving: boolean
  savingError: ErrorObject | null
  acceptedSuccessfully: boolean
}

const initialState: ConfirmInvitationState = {
  isLoading: false,
  loadingError: null,
  model: null,
  token: null,
  isSaving: false,
  savingError: null,
  acceptedSuccessfully: false,
}

export const fetchData = createAsyncThunk<[AdministratorDTO, AdministratorInvitationProtectedDTO]>(
  'confirmInvitation/fetchData',
  async (payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState
    const token = rootState.confirmInvitation.token!

    return Promise.all([
      rootState.settings.user!,
      administratorsApi.getInvitationProtected(token)
    ])
  },
)

export const confirmInvitation = createAsyncThunk<void, ConfirmInvitationFormData>(
  'confirmInvitation/accept',
  async (payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState
    const token = rootState.confirmInvitation.token!

    return administratorsApi.confirmInvitation(token, payload)
  }
)

export const confirmInvitationSlice = createSlice({
  name: 'confirmInvitation',
  initialState,
  reducers: {
    initPage: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload
      state.acceptedSuccessfully = false
    },
    clearPage: (state) => {
      state.acceptedSuccessfully = false
    },
  },
  extraReducers: (builder) => {

    // Fetch data
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      const [user, invitation] = action.payload
      state.isLoading = false
      state.model = createModel(user, invitation)
    })

    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

    // Confirm
    builder.addCase(confirmInvitation.pending, (state) => {
      state.isSaving = true
      state.savingError = null
    })

    builder.addCase(confirmInvitation.fulfilled, (state) => {
      state.isSaving = false
      state.acceptedSuccessfully = true
    })

    builder.addCase(confirmInvitation.rejected, (state, action) => {
      state.isSaving = false
      state.savingError = ErrorUtils.createErrorObjectFromAction(action)
    })
  },
})

// Actions
export const {
  initPage,
  clearPage,
} = confirmInvitationSlice.actions

// Selectors
export const selectIsLoading = (state: RootState) => state.confirmInvitation.isLoading
export const selectLoadingError = (state: RootState) => state.confirmInvitation.loadingError
export const selectModel = (state: RootState) => state.confirmInvitation.model
export const selectToken = (state: RootState) => state.confirmInvitation.token
export const selectIsSaving = (state: RootState) => state.confirmInvitation.isSaving
export const selectSavingError = (state: RootState) => state.confirmInvitation.savingError
export const selectAcceptedSuccessfully = (state: RootState) => state.confirmInvitation.acceptedSuccessfully

export default confirmInvitationSlice.reducer
