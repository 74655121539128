import React, {useState, useEffect} from 'react'
import clsx from "clsx";
import {TeamIcon, IconSize} from '../icons/Icons';

type TeamLogoImageProps = {
  url?: string
  wrapperClassName?: string,
  imageClassName?: string,
  size?: 'sm' | 'base' | 'md' | 'xs'
}

export const TeamLogoImage = ({url, wrapperClassName, imageClassName, size = 'base'}: TeamLogoImageProps) => {
  const [hasError, setHasError] = useState(false)

  const handleError = () => {
    setHasError(true)
  }

  useEffect(() => {
    // reset error anytime url is updated
    setHasError(false)
  }, [url])

  const resultWrapperClassName = clsx(
    'flex items-center justify-center rounded-full bg-white border-1 border-gray-200',
    {
      'w-[80px] h-[80px]': size === "md",
      'w-[70px] h-[70px]': size === "base",
      'w-[60px] h-[60px]': size === "sm",
      'w-[32px] h-[32px]': size === "xs",
    },
    wrapperClassName
  )

  const resultImageClassName = clsx(
    '',
    {
      'w-[56px] h-[56px]': size === "md",
      'w-[53px] h-[53px]': size === "base",
      'w-[41px] h-[41px]': size === "sm",
      'w-[24px] h-[24px]': size === "xs",
    },
    imageClassName
  )

  const iconSize = {
    xs: 300,
    base: 400,
    sm: 500,
    md: 600
  }

  return (
    <div className={resultWrapperClassName}>
      {url && !hasError && <img className={resultImageClassName} src={url} onError={handleError} alt='Team logo'/>}
      {(hasError || !url) && <TeamIcon size={iconSize[size] as IconSize}/>}
    </div>
  )
}
