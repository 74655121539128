import { UserActionDTO } from "./AssociationTypes"

export class UserActionUtils {
  public static getUserName(userAction?: UserActionDTO): string {
    if(!userAction)
      return ''

    if(userAction.userName)
      return userAction.userName

    if(userAction.byUser && userAction.byUser.id)
      return userAction.byUser.id

    return ''
  }
}
