import {TeamDTO} from "../apis/teams-api";
import {AssociationDTO} from "../apis/associations-api";
export interface ImageSource {
  url: string;
  width: number;
}

export class ImageUtils {

  static createTeamLogoUrl(team?: TeamDTO, association?: AssociationDTO): string | undefined {

    let url

    if (team && team.branding?.logo)
      url = team.branding.logo

    if (!url && association && association.branding?.logo)
      url = association.branding.logo

    return this.createImageUrl(url)
  }

  static createTeamBackgroundUrl(team?: TeamDTO, association?: AssociationDTO): string | undefined {

    let url

    if (team && team.branding?.background)
      url = team.branding.background

    if (!url && association && association.branding?.background)
      url = association.branding.background

    if (url)
      return this.createImageUrl(url)

    // TODO: Default background image, maybe set these per sport
    return 'https://images.unsplash.com/photo-1515703407324-5f753afd8be8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80'
  }

  static createImageUrl(url?: string): string | undefined {
    if (!url)
      return

    return process.env.REACT_APP_IMAGE_PREVIEW_URL + url
  }

  static createStaticImageUrl(url?: string): string | undefined {
    if (!url)
      return

    return process.env.PUBLIC_URL + url
  }

  static generateSrcset(images: ImageSource[]): string {
    return images.map(image => `${image.url} ${image.width}w`).join(", ");
  }

}
