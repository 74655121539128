import React from 'react'
import {useAppSelector} from "../../store/hooks";
import {TeamLogoImage} from "../images/TeamLogoImage";
import {useTranslation} from "react-i18next";
import {
  selectSelectedAssociation,
  selectSelectedTeam,
  selectTotalTeams,
  selectBackgroundUrl,
  selectLocalizationsCache
} from "../../redux/settings";
import {Text} from "../text/Text";
import {TeamSwitcher} from "../teams/TeamSwitcher";
import {useScreenSize} from '../../hooks/useScreenSize'
import clsx from "clsx";
import './style.css'
import {Paper as TrinidadPaper} from 'trinidad-ui'
import {ImageUtils} from '../../utils/ImageUtils';
import {TeamUtils} from '../../utils/TeamUtils';

export const AppBannerComponent = () => {
  const {isMobile} = useScreenSize()
  const {t, i18n} = useTranslation()
  const team = useAppSelector(selectSelectedTeam)!
  const association = useAppSelector(selectSelectedAssociation)!
  const totalTeams = useAppSelector(selectTotalTeams)
  const backgroundUrl = useAppSelector(selectBackgroundUrl)
  const hasMultipleTeams = totalTeams > 1
  const localizationCache = useAppSelector(selectLocalizationsCache)

  return (
      <div className={clsx('relative h-[136px] sm:h-[234px] pb-4 w-full bg-cover', isMobile && 'banner-custom-rounded-border')} style={{
        backgroundImage: `url(${backgroundUrl})`
      }}>
        {/* Overlay */}
        <div className='absolute top-0 left-0 bg-navy opacity-[0.9] w-full h-full'></div>
        <div className='relative px-3 text-center pt-8 sm:pt-20'>
          {hasMultipleTeams ? (
            <TeamSwitcher className='w-full sm:w-[412px] mx-auto'/>
          ) : (
            <TrinidadPaper className="!flex h-10 gap-3 w-full items-center pl-3 sm:w-[412px] mx-auto !rounded-sm !shadow-none">
              <TeamLogoImage url={ImageUtils.createTeamLogoUrl(team, association)} size='xs'/>
              <Text className='truncate' weight='medium' text={TeamUtils.getTeamGenderAndCategory(localizationCache, i18n.language, team, t)} />
            </TrinidadPaper>
          )}
        </div>
    </div>
  )
}
