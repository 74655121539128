import React from 'react'
import clsx from "clsx";
import {useTranslationResult} from "../../hooks/useTranslationResult";
import {buildClasses} from './tailwindClasses'
export type TextWeight = 'bold' | 'medium' | 'normal'
export type TextSize = 'xs' | 'sm' | 'md' | 'xl' | '2xl' | '3xl'

export type TextProps = {
  className?: string
  weight?: TextWeight
  size?: TextSize
  children?: React.ReactNode
  text?: string | null
  textKey?: string
  consistentSize?: boolean
  addColon?: boolean

  // H1				-	31.5px	700r
  // Page Title		-	31.5px	500
  // H2				-	25.76px	500
  // H3				-	20.3px	500
  // Dialog title	-	18px	700
  // Page Subtitle	-	17.5px	500
  // Team Name		-	17px	500
  // Label			-	16px	400
  // Content			-	14px	400
  // Subtext			-	12px	400
}

const createTextClasses = (size: TextSize, weight?: TextWeight, consistentSize = false): string => {
  const sizeClassName = buildClasses(size, consistentSize)

  const weightClassName = clsx({
    'font-medium': weight === 'medium',
    'font-bold': weight === 'bold'
  })

  return clsx(sizeClassName, weightClassName)
}

const useTextContent = ({
                          text,
                          textKey,
                          children,
                          addColon
                        }: { text?: string | null, textKey?: string, children?: React.ReactNode, addColon?: boolean }) => {

  let result

  const translation = useTranslationResult(text || undefined, textKey)

  if (children) return children

  if (children)
    result = children
  else
    result = translation

  if (addColon)
    result += ":"

  return result
}

export const H1 = ({size = '3xl', className, weight = 'bold', children, text, textKey, addColon, consistentSize}: TextProps) => {
  const content = useTextContent({text, textKey, children, addColon})
  return <h1 className={clsx(createTextClasses(size, weight, consistentSize), className)}>{content}</h1>
}

export const H2 = ({size = '2xl', className, weight = 'medium', children, text, textKey, addColon, consistentSize}: TextProps) => {
  const content = useTextContent({text, textKey, children, addColon})
  return <h1 className={clsx(createTextClasses(size, weight, consistentSize), className)}>{content}</h1>
}

export const H3 = ({size = 'xl', className, weight = 'medium', children, text, textKey, addColon, consistentSize}: TextProps) => {
  const content = useTextContent({text, textKey, children, addColon})
  return <h3 className={clsx(createTextClasses(size, weight, consistentSize), className)}>{content}</h3>
}

export const Subtitle = ({size = 'md', className, weight = 'medium', children, text, textKey, addColon, consistentSize}: TextProps) => {
  const content = useTextContent({text, textKey, children, addColon})
  return <h5 className={clsx(createTextClasses(size, weight, consistentSize), className)}>{content}</h5>
}

export const Text = ({size = 'sm', className, weight = 'normal', children, text, textKey, addColon, consistentSize}: TextProps) => {
  const content = useTextContent({text, textKey, children, addColon})
  return <p className={clsx(createTextClasses(size, weight, consistentSize), className)}>{content}</p>
}

export const Caption = ({size = 'xs', className, weight = 'normal', children, text, textKey, addColon, consistentSize}: TextProps) => {
  const content = useTextContent({text, textKey, children, addColon})
  return <p className={clsx(createTextClasses(size, weight, consistentSize), className)}>{content}</p>
}
