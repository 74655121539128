import React from 'react'
import {Outlet} from 'react-router-dom'
import {useAppSelector} from "../../store/hooks";
import {selectHasAtLeastOneTeam} from '../../redux/settings'
import {NoTeamMessageComponent} from '../../components/no-team-message-component/NoTeamMessageComponent';
/**
 * Wrapper around routes that are available only if association has at least one team to be managed.
 */
export const TeamRequiredRouteWrapper = () => {
  const hasAtLeastOneTeam = useAppSelector(selectHasAtLeastOneTeam)

  return (
    hasAtLeastOneTeam ? <Outlet /> : <NoTeamMessageComponent />
  )
}
