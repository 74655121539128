import {ApiBase} from "./core/api-base";
import {Price} from "../utils/AssociationTypes";

export type GameConfigDTO = {
  id: string
  allowedPrices: Price[]
  poolContributionPercentage: number
  associationContributionPercentage: number
}

export type OperatorsConfigDTO = {
  invitationExpiryHours: number
  maxAdministratorsPerOperator: number
}

export type ConfigurationDTO = {
  gameConfig: GameConfigDTO
  operatorsConfiguration: OperatorsConfigDTO
}

export class ConfigurationApi extends ApiBase {

  async getConfiguration(): Promise<ConfigurationDTO> {
    return this.$getSingle<ConfigurationDTO>('/configuration')
  }

  async checkBadWords(textMap: Record<string, string>): Promise<string[]> {
    return this.$post<Record<string, string>, string[]>("/configuration/bad-words-check", textMap)
  }

}

export const configurationApi = new ConfigurationApi()
