import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import auth from '../../../auth/auth'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";

interface SignupState {
  isLoggingIn: boolean,
  loginError: ErrorObject | null,
  loginResult: any
}

const initialState: SignupState = {
  isLoggingIn: false,
  loginError: null,
  loginResult: {},
}

export const login = createAsyncThunk(
  'signup/login',
  async () => {
    // const { email, password } = payload
  },
)

export const logout = createAsyncThunk(
  'signup/logout',
  async () => {
    auth.doLogout()

    // eslint-disable-next-line no-restricted-globals
    //location.reload()
  },
)


export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoggingIn = true
      state.loginError = null
    })

    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggingIn = false
      state.loginResult = action.payload
    })

    builder.addCase(login.rejected, (state, action) => {
      state.isLoggingIn = false
      state.loginError = ErrorUtils.createErrorObjectFromAction(action)
    })

  },
})

// Actions
// export const {} = signupSlice.actions

// Selectors
export const selectIsLoggingIn = (state: RootState) => state.signup.isLoggingIn
export const selectLoginError = (state: RootState) => state.signup.loginError

export default signupSlice.reducer
