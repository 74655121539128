import {TeamDTO} from "../../../apis/teams-api";
import {ConfigurationDTO} from "../../../apis/configuration-api";

export type DrawAddPageModel = {
  teams: TeamDTO[],
  configuration: ConfigurationDTO,
}

export const createModel = (teams: TeamDTO[], configuration: ConfigurationDTO): DrawAddPageModel => {

  return {
    teams,
    configuration,
  }
}
