import {ApiBase, PageResponse} from './core/api-base'
import {CategoryDTO} from "./categories-api";
import {SportDTO} from "./sports-api";
import {AssociationDTO} from "./associations-api";
import {Gender, Price, UserActionDTO} from "../utils/AssociationTypes";

export interface TeamBrandingDTO {
  logo?: string
  background?: string
  color?: string
}

export interface TeamDTO {
  id: string
  name: string
  shortName?: string
  description?: string
  code?: string
  gender: Gender
  association?: AssociationDTO
  sport: SportDTO
  category: CategoryDTO
  branding?: TeamBrandingDTO
  startMonth: number
  created?: UserActionDTO
  updated?: UserActionDTO
}

export type MarketingMaterialsType = 'print_lg_pdf' | 'print_md_img'

export type MarketingTeamPublicDataDTO = {
  teamId: string
  teamName: string
  associationId: string
  associationName: string
  backgroundColor: string
  foregroundColor: string
  buyUrl: string
  backgroundImageUrl: string
  logoUrl: string
  hasNoAssociation: boolean
}

export type MarketingTeamActiveDrawPublicDataDTO = {
  drawId: string
  price: Price
  winAmount: Price
  salesStartTime: string
  salesEndTime: string
  drawingTime: string
  hasNoDraw: boolean
}

class TeamsApi extends ApiBase {

  async getTeams(pageIndex: number, pageSize: number, associationId: string, addDrawInfo = false): Promise<PageResponse<TeamDTO>> {
    return this.$getPaged<TeamDTO>('/teams', pageIndex, pageSize, {associationId, drawInfo: addDrawInfo})
  }

  async getTeam(userId: string): Promise<TeamDTO> {
    return this.$getSingle('/teams/' + userId)
  }

  async searchAll(searchParameters: Record<string, string | number | boolean>): Promise<TeamDTO[]> {
    const response = await this.$getPaged<TeamDTO>(`/teams`, 0, 10_000, searchParameters)
    return response.items
  }

  async getNumberOfFavoritesPlayers(teamId: string): Promise<number> {
    return this.$getSingle('/teams/' + teamId + '/favorites-count')
  }

  async downloadMaterials(teamId: string, materialsType: MarketingMaterialsType): Promise<void> {
    return this.$download('/teams/' + teamId + '/materials/' + materialsType)
  }

  async getMarketingTeamPublicData(teamId: string): Promise<MarketingTeamPublicDataDTO> {
    return this.$getSingle('/teams/' + teamId + '/materials-team-data')
  }

  async getMarketingTeamActiveDrawPublicData(teamId: string): Promise<MarketingTeamActiveDrawPublicDataDTO> {
    return this.$getSingle('/teams/' + teamId + '/materials-active-draw-data')
  }

}

export const teamsApi = new TeamsApi()
