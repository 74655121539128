export class RetryError extends Error {
  originalError: Error;
  title: string;
  text: string;
  errorMessage: string;

  constructor({
    originalError,
    title = '',
    text = '',
    errorMessage
  }: {
    originalError: Error;
    title?: string;
    text?: string;
    errorMessage: string;
  }) {
    super(errorMessage);
    this.originalError = originalError;
    this.errorMessage = errorMessage;
    this.title = title;
    this.text = text;
  }
}
