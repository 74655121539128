import {
  AdministratorAssociationDTO,
  AdministratorAssociationStatus,
  AdministratorDTO
} from "../../../apis/administrators-api";
import {AssociationDTO} from "../../../apis/associations-api";
import {TeamDTO} from "../../../apis/teams-api";
import {AdministratorUtils} from "../../../utils/AdministratorUtils";

export type AdministratorsUser = {
  id: string
  name: string
  status: AdministratorAssociationStatus
  isChairman: boolean
  phone?: string
  email?: string
  startDate: string
  requestDate?: string
  associationName: string
  expiry?: string
  isPendingExpired: boolean
}

export type AdministratorsModel = {
  activeUsers: AdministratorsUser[]
  pendingUsers: AdministratorsUser[]
  inactiveUsers: AdministratorsUser[]
  numberOfUsersForLimit: number
}

export const createModel = (users: AdministratorDTO[], currentAssociation: AssociationDTO, _currentTeam: TeamDTO): AdministratorsModel => {


  const filterUsers = (filterFunction: (administratorAssociation: AdministratorAssociationDTO) => boolean): AdministratorDTO[] => {
    return users.filter(it => filterFunction(findCurrentAdministratorAssociation(it, currentAssociation)))
  }

  const activeAdministrators = filterUsers(it => it.status === 'CONFIRMED')
  const pendingNonExpiredAdministrators = filterUsers(it => AdministratorUtils.isAdministratorPendingActive(it))
  const canceledOrPendingExpiredAdministrators = filterUsers(it => it.status === 'CANCELED' || AdministratorUtils.isAdministratorPendingExpired(it))

  const numberOfUsersForLimit = activeAdministrators.length + pendingNonExpiredAdministrators.length

  return {
    activeUsers: activeAdministrators.map(it => createUserModel(it, findCurrentAdministratorAssociation(it, currentAssociation))),
    inactiveUsers: canceledOrPendingExpiredAdministrators.map(it => createUserModel(it, findCurrentAdministratorAssociation(it, currentAssociation))),
    pendingUsers: pendingNonExpiredAdministrators.map(it => createUserModel(it, findCurrentAdministratorAssociation(it, currentAssociation))),
    numberOfUsersForLimit,
  }
}

const createUserModel = (administrator: AdministratorDTO, administratorAssociation: AdministratorAssociationDTO): AdministratorsUser => {

  return {
    id: administrator.id!,
    email: AdministratorUtils.getTemporaryOrRealEmail(administrator, administratorAssociation),
    isChairman: administratorAssociation.roles.includes('CHAIRMAN'),
    name: AdministratorUtils.getTemporaryOrRealName(administrator, administratorAssociation),
    phone: AdministratorUtils.getTemporaryOrRealPhone(administrator, administratorAssociation),
    status: administratorAssociation.status,
    requestDate: administratorAssociation.created?.time,
    startDate: administratorAssociation.statusDate!,
    associationName: administratorAssociation.association.name,
    expiry: administratorAssociation.expiry,
    isPendingExpired: AdministratorUtils.isAdministratorPendingExpired(administratorAssociation)
  }
}

const findCurrentAdministratorAssociation = (administrator: AdministratorDTO, currentAssociation: AssociationDTO) => {
  return administrator.associations!.find(association => association.association!.id === currentAssociation.id)!
}
