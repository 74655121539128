import {ApiBase} from "./core/api-base";

export interface GlobalMessage {
    shouldDisplay: boolean
    message: string
}

export class CmsApi extends ApiBase {

    async getLoggedInMessage(): Promise<GlobalMessage> {
        return this.$getSingle<GlobalMessage>('/cms/global-messages/logged-in')
    }

    async getLoggedOutMessage(): Promise<GlobalMessage> {
        return this.$getSingle<GlobalMessage>('/cms/global-messages/logged-out')
    }

}

export const cmsApi = new CmsApi()
