import {useTranslation} from "react-i18next";
import {useMemo} from "react";

/**
 * Hooks that enables getting static or translated value.
 * @param label
 * @param labelKey
 * @param defaultValue
 */
export const useTranslationResult = (label?: string | null, labelKey?: string, defaultValue?: string, keyArguments?: Record<string, any>) => {

  const {t} = useTranslation()

  const result = useMemo(() => {

    if (label)
      return label

    if (labelKey)
      return t(labelKey, keyArguments)

    if (defaultValue)
      return defaultValue

    return ''

  }, [label, labelKey, defaultValue, t, keyArguments])

  return result
}
