import {ApiBase, PageResponse} from "./core/api-base";

export interface LocalizationDTO {
  id: string
  entityId: string
  entityName: string
  propertyName: string
  propertyValue: string
  locale: string
}

export class LocalizationsApi extends ApiBase {

  async getLocalizations(pageIndex: number, pageSize: number): Promise<PageResponse<LocalizationDTO>> {
    return this.$getPaged<LocalizationDTO>('/localizations', pageIndex, pageSize)
  }

  async getAll(): Promise<LocalizationDTO[]> {
    const localizationsPage = await this.getLocalizations(0, 10_000)
    return localizationsPage.items
  }

}

export const localizationsApi = new LocalizationsApi()
