import {TeamUtils} from '../../../utils/TeamUtils';
import {TeamDTO} from "../../../apis/teams-api";
import {AssociationDrawStatsReport, AssociationDTO} from "../../../apis/associations-api";
import {DrawDTO} from "../../../apis/draws-api";
import {AdministratorDTO} from "../../../apis/administrators-api";
import {DrawType, Gender, Price} from "../../../utils/AssociationTypes";
import {DrawUtils} from "../../../utils/DrawUtils";
import {ImageUtils} from "../../../utils/ImageUtils";
import {PageResponse} from "../../../apis/core/api-base";
import {TEAM_PRIMARY_COLOR_DEFAULT, TEAM_SECONDARY_COLOR_DEFAULT} from '../../../constants/colors';
import {UserActionUtils} from '../../../utils/UserUtils';
import {CategoryDTO} from "../../../apis/categories-api";

function createActiveDrawObject(draw :DrawDTO) {
  return {
    id: draw.id,
    drawName: draw.name,
    homeTeamId: draw.team.id,
    teamName: TeamUtils.getTeamName(draw.team),
    teamLogoUrl: ImageUtils.createTeamLogoUrl(draw.team, draw.association),
    lowestWinningAmount: DrawUtils.calculateAssociationIncome(draw),
    numberOfSoldTickets: DrawUtils.calculateTotalTickets(draw),
    startTime: draw.salesStart,
    closingTime: draw.salesEnd,
    originalClosingTime: draw.originalSalesEnd,
    numberOfPlayers: DrawUtils.calculateTotalPlayers(draw),
    ticketPrice: draw.price,
    drawType: draw.type,
    firstBrandingColor: draw.association?.branding?.color || TEAM_PRIMARY_COLOR_DEFAULT,
    secondBrandingColor: draw.association?.branding?.secondColor || TEAM_SECONDARY_COLOR_DEFAULT,
    playerIncome: DrawUtils.calculatePlayerIncome(draw),
    jackpotSize: DrawUtils.calculateJackpotSize(draw),
    createdBy: UserActionUtils.getUserName(draw.created)
  }
}


export type LastDrawModel = {
  drawName: string
  ticketPrice: Price
  numberOfPlayers: number
  numberOfTickets: number
  startTime: string
  closingTime: string
  originalClosingTime: string
  associationIncome: Price
  drawType: DrawType
  homeGame?: boolean
  jackpotSize: Price
  createdBy: string
  winningTicketNumber?: string
}

export type ActiveDrawModel = {
  id: string
  drawName: string
  lowestWinningAmount: Price
  numberOfSoldTickets?: number
  homeTeamId: string
  teamName: string
  teamLogoUrl?: string
  otherTeamName?: string
  otherTeamLogoUrl?: string
  startTime: string
  closingTime: string
  originalClosingTime: string
  ticketPrice: Price
  numberOfPlayers: number
  drawType: DrawType
  drawObjective?: string
  firstBrandingColor: string
  secondBrandingColor: string
  playerIncome: Price
  jackpotSize: Price
  teamGender?: string
  otherTeamGender?: string
  teamCategory?: CategoryDTO
  otherTeamCategory?: CategoryDTO
  createdBy: string
  homeGame?: boolean
}

export type AdministratorModel = {
  id: string
  firstName: string
  lastName: string
  isMe: boolean
  role: string
}

export type HomePageModel = {
  teamId: string
  teamShortName?: string
  teamName: string
  teamLogoUrl?: string
  teamGender: Gender
  associationId: string
  associationName: string
  associationBankNumber: string
  selectedYear: string
  selectedYearIncome: Price
  selectedYearSupporters: number
  lastDraw: LastDrawModel | null
  nextMatchDraw: ActiveDrawModel | null
  nextEventDraw: ActiveDrawModel | null
  administrators: AdministratorModel[]
}

export function createModel(
  team: TeamDTO,
  association: AssociationDTO,
  completedDrawsPage: PageResponse<DrawDTO>,
  activeMatchDrawsPage: PageResponse<DrawDTO>,
  activeEventDrawsPage: PageResponse<DrawDTO>,
  administratorsPage: PageResponse<AdministratorDTO>,
  reportDtos: AssociationDrawStatsReport[],
  currentAdministrator: AdministratorDTO,
  numberOfFavorites: number
): HomePageModel {

  const model: HomePageModel = {
    teamId: team.id,
    teamName: team.name,
    teamShortName: team.shortName,
    teamLogoUrl: ImageUtils.createTeamLogoUrl(team, association),
    teamGender: team.gender,
    associationId: association.id,
    associationName: association.name,
    associationBankNumber: association.bankDetails?.accountNumber || '',
    selectedYear: TeamUtils.getSeasonYearLabel(team.startMonth),
    selectedYearIncome: {} as Price,
    selectedYearSupporters: numberOfFavorites,
    lastDraw: null,
    nextMatchDraw: null,
    nextEventDraw: null,
    administrators: []
  }

  if (completedDrawsPage.items.length > 0) {
    const draw = completedDrawsPage.items[0]

    const associationIncome = DrawUtils.calculateAssociationIncome(draw)

    model.lastDraw = {
      drawName: draw.name,
      associationIncome,
      startTime: draw.salesStart,
      closingTime: draw.salesEnd,
      originalClosingTime: draw.originalSalesEnd,
      numberOfPlayers: draw.results?.stats.totalPlayers || 0,
      numberOfTickets: draw.results?.stats.totalRows || 0,
      ticketPrice: draw.price,
      drawType: draw.type,
      jackpotSize: DrawUtils.calculateJackpotSize(draw),
      createdBy: UserActionUtils.getUserName(draw.created),
      winningTicketNumber: DrawUtils.getFormattedWinnerNumber(draw)
    }

    if (draw.type === 'MATCH') {
      model.lastDraw.homeGame = draw.isHomeGame
    }
  }

  if (activeMatchDrawsPage.items.length > 0) {
    const draw = activeMatchDrawsPage.items[0]

    model.nextMatchDraw = {
      ...createActiveDrawObject(draw),
      teamGender: draw.team.gender,
      otherTeamName: TeamUtils.getTeamName(draw.opponentTeam!),
      otherTeamLogoUrl: ImageUtils.createTeamLogoUrl(draw.opponentTeam, draw.opponentTeam!.association),
      otherTeamGender: draw.opponentTeam && draw.opponentTeam.gender,
      teamCategory: draw.team.category,
      otherTeamCategory: draw.opponentTeam && draw.opponentTeam.category,
      homeGame: draw.isHomeGame
    }
  }

  if (activeEventDrawsPage.items.length > 0) {
    const draw = activeEventDrawsPage.items[0]

    model.nextEventDraw = {
      ...createActiveDrawObject(draw),
      drawObjective: draw.objective
    }
  }

  model.administrators = administratorsPage.items.map(it => {

    const currentAdministratorAssociation = it.associations?.find(administratorAssociation => administratorAssociation.association.id === association.id)
    let role = ''

    if (currentAdministratorAssociation)
      role = currentAdministratorAssociation.roles.includes('CHAIRMAN') ? 'CHAIRMAN' : 'ADMIN'

    const administrator: AdministratorModel = {
      id: it.id!,
      firstName: it.firstName || '',
      lastName: it.lastName || '',
      isMe: it.id === currentAdministrator.id,
      role
    }

    return administrator
  }).sort((a, b) => (a.role === 'CHAIRMAN' ? -1 : b.role === 'CHAIRMAN' ? 1 : 0))

  let totalSeasonIncome = 0

  if(reportDtos.length) {
    totalSeasonIncome = reportDtos[0].summary.associationContribution.amount
  }

  const currency = (reportDtos.length && reportDtos[0].stats.buy.currency) || 'SEK'
  model.selectedYearIncome = {
    amount: totalSeasonIncome,
    currency
  }
  return model
}
