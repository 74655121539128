import React from 'react'
import clsx from "clsx";
import {AppHeaderTopNavbarComponent} from "./AppHeaderTopNavbarComponent";

export const AppHeaderComponent = () => {
  return (
    <header className={clsx('fixed top-0 left-0 right-0 z-20 xl:z-[1101]')}>
      <AppHeaderTopNavbarComponent/>
    </header>
  )
}
