import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../store/hooks";
import {selectIsLoggedIn} from "../../redux/settings";
import {Text} from '../text/Text';
import {IconButton} from '../buttons/IconButton';
import {cmsApi} from "../../apis/cms-api";

export const ErrorMessageComponent = () => {
  const {i18n} = useTranslation()
  const [message, setMessage] = useState('')
  const [isDisplayed, setIsDisplayed] = useState<boolean | null>(false)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  const onCloseHandler = () => {
    setIsDisplayed(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {shouldDisplay, message} = isLoggedIn ? await cmsApi.getLoggedInMessage() : await cmsApi.getLoggedOutMessage()

        setIsDisplayed(shouldDisplay)
        setMessage(message!)
      } catch (error) {
        // no need to handle error
      }
    }

    fetchData()
  }, [i18n.language, isLoggedIn])

  return (
    isDisplayed && message ? (<div className="fixed top-0 w-full p-3 text-center bg-yellow text-white z-[1200]">
      <IconButton size={200} icon="close" className='cursor-pointer float-right !m-2' color='white' onClick={onCloseHandler}/>
      <Text text={message} weight='medium' className='py-2'/>
    </div>) : null
  )
}
