import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectIsLoggedIn } from '../../redux/settings'

/**
 * Wrapper around routes that are available only to non logged-in users.
 */
export const GuestRoutesWrapper = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)

  return (
    isLoggedIn ? <Navigate to='/' /> : <Outlet />
  )
}
