import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'

import {ErrorObject, ErrorUtils} from "../../utils/ErrorUtils";
import {Button} from "../buttons/Button";
import {Loader} from "../loaders/Loader";

interface PopupProps {
  visible: boolean,
  children: React.ReactNode,
  onClose: () => void,
  className?: string,
  loading?: boolean,
  error?: ErrorObject
}

export const Popup = ({ visible, children, onClose, className, loading, error }: PopupProps) => {

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" >
            <div className={`fixed h-full w-full bg-navy bg-opacity-[0.5]`} />
          </div>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className={clsx(`flex min-h-full items-center justify-center py-4 px-2 text-center bottom-0`)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx('w-full max-w-xl transform overflow-hidden oppacity-10 bg-white text-left align-middle dev-shadow-popup transition-all bg-right-top bg-no-repeat', className)}
              >
                {
                  loading ?
                    (
                      <div className='p-10'><Loader /></div>
                    ) :
                    error ?
                      (
                        <div>
                          <div className='bg-red-500 px-5 py-3 text-white rounded-lg my-10'>
                            {ErrorUtils.getErrorMessage(error)}
                          </div>
                          <Button
                            label='Close'
                            type='button'
                            onClick={onClose}
                          />
                        </div>) :
                      children
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
