import {LocalizationDTO} from "../apis/localizations-api";
import {CategoryDTO} from "../apis/categories-api";

export type LocalizationsCache = Record<string, Record<string, Record<string, Record<string, string>>>>

/**
 * Backoffice language codes are used for defining translations of dynamic data but backoffice
 * might use different language codes and in that case we need to remap language codes that might differ.
 */
const LANGUAGE_REMAP: Record<string, string> = {
  sv: 'sv-SE',
  en: 'en-US'
}


export class LocalizationUtils {

  static translateCategory(category: CategoryDTO, cache: LocalizationsCache, locale: string): string | undefined {

    if (!category)
      return

    if (!cache)
      return category.name

    return this.translate(cache, 'categories', category.id, 'name', locale) || category.name

  }

  static translate(cache: LocalizationsCache, entityName: string, entityId: string, propertyName: string, locale: string): string | undefined {

    if (!cache)
      return

    if (!cache[entityName])
      return

    if (!cache[entityName][entityId])
      return

    if (!cache[entityName][entityId][propertyName])
      return

    // We must remap language code used in this app to language code used for translations of dynamic data
    locale = LANGUAGE_REMAP[locale] || locale

    return cache[entityName][entityId][propertyName][locale]
  }


  static createCache(localizations: LocalizationDTO[]): LocalizationsCache {

    // Group localization objects per entity and then per property and then per locale
    const resultMap: LocalizationsCache = {};

    // Loop through each object in the array
    for (const object of localizations) {

      const {entityName, entityId, propertyName, locale, propertyValue} = object;

      // Create nested maps if they don't exist
      if (!resultMap[entityName]) {
        resultMap[entityName] = {};
      }
      if (!resultMap[entityName][entityId]) {
        resultMap[entityName][entityId] = {};
      }
      if (!resultMap[entityName][entityId][propertyName]) {
        resultMap[entityName][entityId][propertyName] = {};
      }

      // Set the locale to propertyValue mapping
      resultMap[entityName][entityId][propertyName][locale] = propertyValue;
    }

    return resultMap
  }

}
