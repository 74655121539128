import React, {useEffect} from 'react'
import clsx from 'clsx'

import {selectIsFullScreenRoute, setTitle} from '../../redux/settings'
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {PageErrorBoundary} from "./PageErrorBoundary";
import {Loader} from "../loaders/Loader";
import {ErrorUtils} from "../../utils/ErrorUtils";
import {useTranslation} from "react-i18next";
import {PageContent} from "./PageContent";
import {Subtitle} from "../text/Text";

type PageProps = {
  title?: string
  titleKey?: string
  children: React.ReactNode,
  error?: any,
  className?: string,
  isLoading?: boolean
  errorContent?: React.ReactNode
}

export const Page = React.forwardRef<HTMLDivElement, PageProps>(({
                                                                   title,
                                                                   titleKey,
                                                                   children,
                                                                   error,
                                                                   className,
                                                                   isLoading,
                                                                   errorContent
                                                                 }, ref) => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const isFullScreenRoute = useAppSelector(selectIsFullScreenRoute)

  useEffect(() => {

    let titleResult = title || (titleKey && t(titleKey)) || ''

    // Set global state page title
    dispatch(setTitle(titleResult))

    let documentTitle = 'Associations Admin Web'

    if (titleResult) {
      documentTitle = titleResult + ' - ' + documentTitle
    }

    // Update browser title
    window.document.title = documentTitle

  }, [dispatch, title, titleKey, t])

  return (
    <PageErrorBoundary>
      <div ref={ref} className={clsx(!isFullScreenRoute && 'pb-9', className)}>
        {error ?
          <PageContent>
            <div
              className='text-white bg-red-500 mx-3 my-10 p-3 rounded-lg'>
              <Subtitle>{ErrorUtils.getErrorMessage(error)}</Subtitle>
            </div>
            {errorContent}
          </PageContent>
          :
          isLoading ?
            <div className='min-h-[200px] mt-[100px]'>
              <Loader/>
            </div>
            :
            children}
      </div>
    </PageErrorBoundary>
  )
})

Page.displayName = 'Page'


