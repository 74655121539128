import {useNavigate} from "react-router";
import React, {useCallback} from "react";
import {Page} from "../../components/page/Page";
import {Button} from "../../components/buttons/Button";
import {useTranslation} from "react-i18next";

export const RouteNotAvailablePage = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  const onTryAgainClick = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <Page titleKey='pages.routeNotAvailable.title'>
      <div className='text-center'>
        <div className='text-purple-light font-bold text-3xl mt-32'>{t('pages.routeNotAvailable.subtitle')}</div>
        <div className='text-neutral-light mt-12'>{t('pages.routeNotAvailable.description')}</div>
        <Button onClick={onTryAgainClick} label='Try again' className='mt-20'/>
      </div>
    </Page>
  )
}
