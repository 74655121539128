import React, {useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Button as TrinidadButton} from 'trinidad-ui'
import {ButtonSpinner} from "./ButtonSpinner";
import {GenericIcon} from "../icons/Icons";

type ButtonProps = {
  label?: string,
  labelKey?: string,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  loading?: boolean,
  disabled?: boolean,
  type?: 'submit' | 'button',
  className?: string
  variant?: 'primary' | 'dark' | 'transparent'
  to?: string
  icon?: string
  form?: string
}

export const Button = React.forwardRef(({
                                          label,
                                          labelKey,
                                          onClick,
                                          loading,
                                          disabled,
                                          type = 'button',
                                          className,
                                          variant = 'primary',
                                          to,
                                          icon,
                                          form
                                        }: ButtonProps, ref) => {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const onClickWrapper = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      if (loading || disabled)
        return

      if (onClick) {
        onClick(event)
      } else if (to) {
        navigate(to)
      }

    }, [loading, disabled, onClick, navigate, to])

  const buttonClassName = clsx(
    className
  )

    const labelResult = useMemo(() => {
      if (label)
        return label

      if (labelKey) {
        return t(labelKey)
      }

      return ''
    }, [label, labelKey, t])

    return (
      <TrinidadButton onClick={onClickWrapper}
                      type={type}
                      className={buttonClassName}
                      disabled={loading || disabled}
                      focus={variant === 'primary'}
                      inverted={variant === 'dark'}
                      transparent={variant === 'transparent'}
                      elemRef={ref}
                      form={form}
      >
        {loading ?
          <div className='flex justify-center items-center'>
            <ButtonSpinner />
          </div>
        :
          <>
            {icon && <GenericIcon className={clsx(labelResult && 'mr-1')} icon={icon}/>}
            {labelResult}
          </>
        }
      </TrinidadButton>
    )
  }
)
