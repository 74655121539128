import {RefObject} from "react"
export class ScrollUtils {

  static scrollingContainer: HTMLElement | null

  static scrollToTop() {
    if (this.scrollingContainer) {
      this.scrollingContainer.scrollTo({
        top: 0
      })
    }
  }

  static scrollIntoView(ref: RefObject<HTMLElement>, offset: number = 0) {
    if (ref.current) {
      // We need to calculate header height and include it in offset since it is positioned fixed,
      // otherwise the ref element would be under it and not visible
      const headerHeight = document.querySelector('header')?.clientHeight || 0
      const topOffset = ref.current.getBoundingClientRect().top + window.pageYOffset
      // optionally we can add offset to give element more space and improve UI
      const adjustedOffset = topOffset - headerHeight + offset
      window.scrollTo({ top: adjustedOffset, behavior: "smooth" })
    }
  }
}
