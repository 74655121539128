import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useSelector} from "react-redux";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectIsFrozenScreenRoute,
    selectIsLoggedIn,
    selectIsMenuMinimized,
    selectUser,
    setMenuMinimized,
    setUserMenuMinimized
} from "../../redux/settings";
import {Brand as TrinidadBrand} from 'trinidad-ui'
import {Text} from '../text/Text';
import {useScreenSize} from '../../hooks/useScreenSize'
import {LoginIcon, MenuIcon, UserIcon} from '../icons/Icons';

export const AppHeaderTopNavbarComponent = () => {
  const dispatch = useAppDispatch()
  const {i18n} = useTranslation()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isMenuClosed = useSelector(selectIsMenuMinimized)
  const currentUser = useAppSelector(selectUser)
  const {isDesktop} = useScreenSize()
  const isFrozenScreen = useAppSelector(selectIsFrozenScreenRoute)

  const [brandClicks, setBrandClicks] = useState(0)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (timerRef.current)
        clearTimeout(timerRef.current)
    }
  }, [])

  // Changing language requires N consecutive clicks in a short amount of time
  const onBrandClick = useCallback(() => {
    setBrandClicks(prev => prev + 1)

    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }

    timerRef.current = setTimeout(() => setBrandClicks(0), 500)

    if (brandClicks === 10) {
      i18n.language === 'en' ? i18n.changeLanguage('sv') : i18n.changeLanguage('en')
      setBrandClicks(0)
    }
  }, [i18n, brandClicks])

  const onShowMenu = useCallback(() => {
    dispatch(setMenuMinimized(false))
  }, [dispatch])

  const onShowUserMenu = useCallback(() => {
    dispatch(setUserMenuMinimized(false))
  }, [dispatch])

    const headerClassName = "h-[44px] bg-navy text-white flex justify-between items-center px-3 sm:px-6 xl:h-[60px]"

    if(isFrozenScreen) {
        return (
            <div className={headerClassName}>
                <Text weight='medium' className='hidden xl:block' textKey='header.title'/>
                <div className='absolute left-1/2 transform -translate-x-1/2' onClick={onBrandClick}>
                    <TrinidadBrand brand='supportern' emblem size={isDesktop ? 150 : 100}/>
                </div>
            </div>
        )
    }

  return (
      <div className={headerClassName}>
      <div onClick={onShowMenu}
           className={clsx('flex items-center gap-3 cursor-pointer xl:hidden', !isMenuClosed && 'invisible')}>
        <MenuIcon size={300} color='white'/>
        <Text weight='medium' className='hidden sm:block' textKey='header.menu'/>
      </div>
      <Text weight='medium' className='hidden xl:block' textKey='header.title'/>
      <div className='absolute left-1/2 transform -translate-x-1/2' onClick={onBrandClick}>
        <NavLink to={isLoggedIn ? '/' : '/signup'}>
          <TrinidadBrand brand='supportern' emblem size={isDesktop ? 150 : 100}/>
        </NavLink>
      </div>
      {isLoggedIn
        ? <div onClick={onShowUserMenu} className='flex gap-3 items-center justify-center cursor-pointer'>
          {/* Using arbitrary media queries to fine tune the length and avoid overlapping with logo */}
          <Text
            className='hidden sm:block truncate sm:max-w-[200px] [@media(min-width:850px)]:max-w-[300px] [@media(min-width:1000px)]:max-w-[360px] [@media(min-width:1350px)]:max-w-[400px]'
            text={`${currentUser?.firstName!} ${currentUser?.lastName!}`}/>
          <UserIcon size={300} color='white'/>
        </div>
        : <div onClick={onShowUserMenu} className='flex gap-3 items-center justify-center cursor-pointer'>
          <Text weight='medium' className='hidden sm:block' textKey='header.login'/>
          <LoginIcon size={300} color='white'/>
        </div>}
    </div>
  )
}
