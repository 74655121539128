import {AdministratorDTO, AdministratorInvitationProtectedDTO} from "../../../apis/administrators-api";
import moment from "moment/moment";

export type ConfirmInvitationPageModel = {
  userDTO: AdministratorDTO
  invitationDTO: AdministratorInvitationProtectedDTO
  isExpired: boolean
  isPending: boolean
}

export const createModel = (user: AdministratorDTO, administratorInvitation: AdministratorInvitationProtectedDTO): ConfirmInvitationPageModel => {

  const now = moment()
  const isExpired = moment(administratorInvitation.expiry).isBefore(now)
  const isPending = administratorInvitation.status === 'PENDING'

  return {
    userDTO: user,
    invitationDTO: administratorInvitation,
    isExpired,
    isPending
  }
}
