import React, {useCallback} from 'react'
import {Drawer as TrinidadDrawer} from 'trinidad-ui'
import {useSelector} from "react-redux";
import {selectIsUserMenuMinimized, setUserMenuMinimized, selectIsChairman, selectIsLoggedIn, selectUser} from "../../redux/settings";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {NavLink} from "react-router-dom";
import {GenericIcon} from "../icons/Icons";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {TearBorder} from '../borders/TearBorder';
import {Subtitle} from "../../components/text/Text";
import ribbon from '../../assets/ribbon.svg';
import {UserIcon} from '../icons/Icons';
import auth from '../../auth/auth';
import {Button} from '../buttons/Button';
export const UserMenu = () => {

  const dispatch = useAppDispatch()
  const isCurrentUserChairman = useAppSelector(selectIsChairman)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isOpen = !useSelector(selectIsUserMenuMinimized)
  const isDimmerDisabled = false
  const user = useAppSelector(selectUser)!

  const onLoginClick = useCallback(() => {
    auth.doLogin()
  }, [])

  const onDimmerClick = useCallback(() => {
    dispatch(setUserMenuMinimized(true))
  }, [dispatch])

  return (
    <TrinidadDrawer
      className={clsx('bg-white text-navy border-r border-grey-300 xl:!z-[1102] !w-[19em]')}
      position='right'
      isOpen={isOpen}
      onDimmerClicked={onDimmerClick}
      isDimmerDisabled={isDimmerDisabled}
      >
      <div className={clsx('relative', isLoggedIn ? 'text-left' : 'text-center')}>
        <div className='bg-green-100 p-6 h-[152px]'>
          <img src={ribbon} alt="" className="absolute right-0 top-0 w-[91px] h-[59px]" />
          {isLoggedIn
            ? (
              <>
                <Subtitle weight='bold' consistentSize text={user.firstName} />
                <Subtitle weight='bold' className='mt-1' consistentSize text={user.lastName} />
              </>
            )
            : (
              <div className='flex flex-col'>
                <UserIcon size={600} />
                <Button labelKey='userMenu.loginButton' onClick={onLoginClick} className='px-10 mt-6 uppercase'/>
              </div>
            )}
        </div>
        <TearBorder className='bg-green-100 -mt-[1px]'/>
      </div>
      <div className='mt-7'>
        {isLoggedIn && <LocalMenuItem titleKey='menu.settings' to='/settings' icon='settings'/>}
        {isCurrentUserChairman && <LocalMenuItem titleKey='menu.chairman' to='/administrators' icon='brands'/>}
        {isLoggedIn &&
          (<div className='px-6'>
              <Button labelKey='userMenu.logout' variant='transparent' className='mt-6 w-full' onClick={() => auth.doLogout()} />
          </div>)
        }
      </div>

    </TrinidadDrawer>
  )
}

type LocalMenuItemProps = {
  titleKey: string
  to: string
  icon: string,
  matchEnd?: boolean
}

const LocalMenuItem = ({titleKey, to, icon, matchEnd = false}: LocalMenuItemProps) => {
  const dispatch = useAppDispatch()

  const onLinkClick = useCallback(() => {
    dispatch(setUserMenuMinimized(true))
  }, [dispatch])

  const {t} = useTranslation()

  return (
    <NavLink end={matchEnd} className="h-12 flex items-center pl-6 tracking-[.3px] relative cursor-pointer" to={to} onClick={onLinkClick}>
      {({ isActive }) => (
        <>
          {isActive && <div className="h-full w-1 absolute left-0 bg-red rounded-r-xl"></div>}
          <GenericIcon size={300} icon={icon} color='white'/>
          <span className={clsx('text-[18px] ml-3', isActive && 'font-medium')} >{t(titleKey)}</span>
        </>
      )}
    </NavLink>
  )
}
