import React from 'react'
import clsx from "clsx";

type PageContentProps = {
  className?: string
  children: React.ReactNode
}

export const PageContent = ({className, children}: PageContentProps) => {
  return (
    <div className={clsx('max-w-[668px] m-auto px-3', className)}>
      {children}
    </div>
  )
}
