import React, {useCallback, useMemo} from 'react'
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
  selectAssociations, selectLocalizationsCache,
  selectSelectedTeam,
  selectTeam,
  selectTeamsPerAssociation,
  selectTotalAssociationsWithTeams,
} from "../../redux/settings";
import {TeamLogoImage} from "../images/TeamLogoImage";
import {TeamDTO} from "../../apis/teams-api";
import {ImageUtils} from "../../utils/ImageUtils";
import SelectBox, {SelectBoxOption} from "../selectbox/SelectBox";
import {TeamUtils} from '../../utils/TeamUtils';
import {useTranslation} from 'react-i18next';

type TeamSwitcherProps = {
  labelKey?: string
  className?: string
}

export const TeamSwitcher = ({labelKey, className}: TeamSwitcherProps) => {
  const dispatch = useAppDispatch()
  const {t, i18n} = useTranslation()
  const associations = useAppSelector(selectAssociations)
  const teamsPerAssociation = useAppSelector(selectTeamsPerAssociation)
  const selectedTeam = useAppSelector(selectSelectedTeam)!
  const totalAssociationsWithTeams = useAppSelector(selectTotalAssociationsWithTeams)
  const localizationsCache = useAppSelector(selectLocalizationsCache)

  const onTeamChange = useCallback((option: SelectBoxOption) => {
    const team = option.data as TeamDTO
    dispatch(selectTeam({teamId: team.id, associationId: team.association!.id}))
  }, [dispatch])

  const teamOptions = useMemo(() => {
    if (!associations || !teamsPerAssociation)
      return []

    const options: SelectBoxOption[] = []

    associations.forEach(association => {
      teamsPerAssociation[association.id].forEach(team => {
        const teamName = TeamUtils.getTeamGenderAndCategory(localizationsCache, i18n.language, team, t)

        options.push({
          name: totalAssociationsWithTeams > 1 ? `${teamName} - ${team.association!.name}` : teamName,
          value: team.id,
          data: team,
          icon: <TeamLogoImage wrapperClassName='min-w-[32px]' url={ImageUtils.createTeamLogoUrl(team, association)} size='xs'/>
        })
      })
    })

    return options
  }, [associations, teamsPerAssociation, localizationsCache, i18n.language, t, totalAssociationsWithTeams])

  const selectedTeamOption = useMemo(() => {
    return teamOptions.find(it => it.value === selectedTeam.id)
  }, [selectedTeam, teamOptions])

  return (
    <SelectBox name='team'
               labelKey={labelKey}
               options={teamOptions}
               selectedOption={selectedTeamOption}
               onChange={onTeamChange}
               className={className}/>
  )
}

