import {ApiBase, PageResponse} from './core/api-base'
import {AssociationDTO} from "./associations-api";
import {UserActionDTO} from "../utils/AssociationTypes";

export type AdministratorAssociationStatus = 'PENDING' | 'CONFIRMED' | 'CANCELED'

export type AdministratorAssociationRole = 'CHAIRMAN' | 'ADMIN'

export interface AdministratorAssociationTemporaryDataDTO {
  confirmed?: boolean
  firstName?: string
  lastName?: string
  phoneNumber?: string
  email?: string
}

export interface AdministratorAssociationCustomDataDTO {
  notifyOnCloseDraw: boolean
}

export interface AdministratorAssociationDTO {
  id?: string
  expiry?: string
  association: AssociationDTO
  status: AdministratorAssociationStatus
  roles: AdministratorAssociationRole[]
  created?: UserActionDTO
  statusDate?: string
  customData?: AdministratorAssociationCustomDataDTO
  tempData?: AdministratorAssociationTemporaryDataDTO
  confirmedMarketing?: boolean
}

export interface AdministratorDTO {
  id?: string
  firstName: string
  lastName: string
  personalNumber: string
  phoneNumber?: string
  email?: string
  associations?: AdministratorAssociationDTO[]
}

export interface AdministratorInsertDTO {
  firstName: string
  lastName: string
  personalNumber: string
  phoneNumber: string
  email?: string
  associationId: string
}

export interface AdministratorUpdateDTO {
  id: string
  firstName: string
  lastName: string
  phoneNumber: string
  email?: string
  associationId: string
  customData?: AdministratorAssociationCustomDataDTO
}

export interface AdministratorInvitationDTO {
  associationName: string
  expiry: string
  inviterName: string
  status: AdministratorAssociationStatus
  logo?: string
  confirmedData?: boolean
}

export interface AdministratorInvitationProtectedDTO extends AdministratorInvitationDTO {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  personalNumber: string
  tempFirstName?: string
  tempLastName?: string
  tempPhoneNumber?: string
  tempEmail?: string
}

class AdministratorsApi extends ApiBase {

  async getAdministrators(pageIndex: number, pageSize: number, queryParams: { associationId?: string, status?: AdministratorAssociationStatus }): Promise<PageResponse<AdministratorDTO>> {
    return this.$getPaged<AdministratorDTO>('/administrators', pageIndex, pageSize, queryParams)
  }

  async getAdministrator(userId: string): Promise<AdministratorDTO> {
    return this.$getSingle<AdministratorDTO>('/administrators/' + userId)
  }

  async getAdministratorMe(): Promise<AdministratorDTO> {
    return this.$getSingle<AdministratorDTO>('/administrators/me')
  }

  addAdministrator(dto: AdministratorInsertDTO): Promise<string> {
    return this.$post('/administrators', dto)
  }

  updateAdministrator(id: string, dto: AdministratorUpdateDTO): Promise<void> {
    return this.$put('/administrators/' + id, dto)
  }

  getInvitation(token: string) {
    return this.$getSingle<AdministratorInvitationDTO>('/administrators/invitations/' + token);
  }

  getInvitationProtected(token: string) {
    return this.$getSingle<AdministratorInvitationProtectedDTO>('/administrators/invitations/' + token + '/protected');
  }

  rejectInvitation(token: string) {
    return this.$delete('/administrators/invitations/' + token)
  }

  acceptInvitation(token: string) {
    return this.$post('/administrators/invitations/' + token, {})
  }

  confirmInvitation(token: string, {
    firstName,
    lastName,
    phone,
    email
  }: { firstName: string, lastName: string, phone: string, email: string }) {
    return this.$put('/administrators/invitations/' + token, {firstName, lastName, phoneNumber: phone, email})
  }

  resendInvitation(administratorId: string, associationId: string) {
    return this.$post('/administrators/invitations', {administratorId, associationId})
  }

  deleteAdministratorAssociation(administratorAssociationId: string): Promise<void> {
    return this.$delete('/administrators/' + administratorAssociationId)
  }

  confirmMarketing(administratorAssociationId: string, value: boolean) {
    return this.$put('/administrators/marketing-terms/' + administratorAssociationId, {value})
  }
}

export const administratorsApi = new AdministratorsApi()
