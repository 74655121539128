import {TeamDTO} from '../apis/teams-api';
import {TFunction} from "i18next";
import {LocalizationsCache, LocalizationUtils} from "./LocalizationUtils";
export class TeamUtils {

  static getTeamName(team: TeamDTO): string {
    if (!team) {
      return ''
    }

    const useTeamNameAsName = false
    // to-do: check whether we should use team or association name as display name for teams
    return useTeamNameAsName ? team.name! : team.association?.name!
  }

  static getSeasonYearLabel(seasonStartMonth: number): string {
    const now = new Date();
    const year = now.getFullYear();

    if (now.getMonth() >= seasonStartMonth - 1) {
      return `${year}/${year + 1}`;
    } else {
      return `${year - 1}/${year}`;
    }
  }

  static getTeamGenderAndCategory(localizationsCache: LocalizationsCache, locale: string, team: TeamDTO, t: TFunction, lowercaseCategory: boolean = false): string {
    if (team && team.gender && team.category?.name) {
      const localizedCategoryName = LocalizationUtils.translateCategory(team.category, localizationsCache, locale)!
      const category = lowercaseCategory ? localizedCategoryName.toLowerCase() : localizedCategoryName
      return t('enums.gender.' + team.gender) + ' ' + category
    }

    return ''
  }

}
