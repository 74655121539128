import { useEffect, useState } from 'react'
const SCREEN_SIZES = {
  TABLET_TRESHOLD: 667,
  DESKTOP_TRESHOLD: 1357
}
export const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState<boolean>(false)
  const [isDesktop, setIsDesktop] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      setIsMobile(width <= SCREEN_SIZES.TABLET_TRESHOLD)
      setIsTablet(width > SCREEN_SIZES.TABLET_TRESHOLD && width <= SCREEN_SIZES.DESKTOP_TRESHOLD)
      setIsDesktop(width > SCREEN_SIZES.DESKTOP_TRESHOLD)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {isMobile, isTablet, isDesktop}
}
