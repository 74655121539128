import Keycloak from "keycloak-js";

export const APP_INVITATION_TOKEN_PARAM_NAME = 'invitationToken'

const getAuthUrl = (hostname: string) => {

  const hostnameParts = hostname.split('.')

  let keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL || ''

  // We have two ways of creating keycloak url when we have a subdomain:
  // 1. sub.domain.com -> sub-auth.domain.com
  // 2. sub.domain.com -> auth.sub.domain.com
  // In case hostname has three parts and end with bwloto.xyz the first strategy is applied
  // Otherwise, if host name has three or more parts the second strategy is applied
  if (hostnameParts.length > 2) {

    const keycloakSubdomain = hostnameParts.length === 3 && hostname.endsWith('bwloto.xyz')
      ? hostnameParts[0] + '-auth'
      : 'auth.' + hostnameParts[0]

    const keycloakUrlParts = [...hostnameParts]
    keycloakUrlParts[0] = 'https://' + keycloakSubdomain
    keycloakUrl = keycloakUrlParts.join('.')
  }

  return keycloakUrl
}

const keycloakUrl = getAuthUrl(window.location.hostname)

const _kc = new Keycloak({
  url: keycloakUrl,
  realm: 'operatorPortal',
  clientId: "admin_users"
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void, onErrorCallback: () => void) => {
  _kc.init({
    onLoad: 'check-sso',
    enableLogging: true,
    silentCheckSsoRedirectUri: window.location.origin + process.env.PUBLIC_URL + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {

      if (authenticated) {
        console.log("Keycloak is initialized. User is authenticated.");
      } else {
        console.log("Keycloak is initialized. User is not authenticated.");
      }

      onAuthenticatedCallback();
    })
    .catch((error) => {
      console.error('Error initializing keycloak:', error)
      onErrorCallback()
    });
};

const doLogin = _kc.login;

const doInvitationLogin = (token: string) => {
  let url = _kc.createLoginUrl({
    // When redirected back, we want to go to invitation confirmation page with the same token
    redirectUri: window.location.origin + process.env.PUBLIC_URL + `/confirm-invitation?${APP_INVITATION_TOKEN_PARAM_NAME}=${token}`
  })
  url += '&invitationToken=' + encodeURIComponent(token)
  window.location.assign(url)
}

const doLogout = (redirectUrl?: string) => {
  return _kc.logout({redirectUri: window.location.origin + process.env.PUBLIC_URL + '/' + (redirectUrl || '')})
}

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: () => void) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getUserId = () => _kc.tokenParsed?.sub;

const hasRole = (roles: string[]) => roles.some((role) => _kc.hasRealmRole(role));

export const auth = {
  getAuthUrl,
  initKeycloak,
  doLogin,
  doInvitationLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getUserId,
  hasRole,
};

export default auth;
