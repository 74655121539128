import {AdministratorAssociationDTO, AdministratorDTO} from "../apis/administrators-api";
import moment from "moment";

export class AdministratorUtils {

  static isAdministratorPendingExpired(administratorAssociation: AdministratorAssociationDTO): boolean {

    if (!administratorAssociation)
      return false

    if (administratorAssociation.status !== 'PENDING')
      return false

    const expiry = administratorAssociation.expiry

    if (!expiry)
      return true

    return moment(expiry).isBefore(moment())
  }

  static isAdministratorPendingActive(administratorAssociation: AdministratorAssociationDTO): boolean {

    if (!administratorAssociation)
      return false

    if (administratorAssociation.status !== 'PENDING')
      return false

    const expiry = administratorAssociation.expiry

    if (!expiry)
      return false

    return moment(expiry).isAfter(moment())
  }

  /**
   * Temporary data should be used while it's valid, and it's valid as long aggregation is in status PENDING
   * and user has not confirmed his data.
   * @param administratorAssociation
   */
  static shouldUseTemporaryData(administratorAssociation: AdministratorAssociationDTO): boolean {
    if (!administratorAssociation)
      return false

    return administratorAssociation.status === 'PENDING' && (!administratorAssociation.tempData || !administratorAssociation.tempData.confirmed)
  }

  static getTemporaryOrRealData(administratorAssociation: AdministratorAssociationDTO, tempValue?: string, realValue?: string): string | undefined {

    let value = realValue

    if (this.shouldUseTemporaryData(administratorAssociation) && tempValue) {
      value = tempValue
    }

    return value
  }

  static getTemporaryOrRealEmail(administrator: AdministratorDTO, administratorAssociation: AdministratorAssociationDTO): string | undefined {
    return this.getTemporaryOrRealData(administratorAssociation, administratorAssociation.tempData?.email, administrator.email)
  }

  static getTemporaryOrRealPhone(administrator: AdministratorDTO, administratorAssociation: AdministratorAssociationDTO): string | undefined {
    return this.getTemporaryOrRealData(administratorAssociation, administratorAssociation.tempData?.phoneNumber, administrator.phoneNumber)
  }

  static getTemporaryOrRealFirstName(administrator: AdministratorDTO, administratorAssociation: AdministratorAssociationDTO): string | undefined {
    return this.getTemporaryOrRealData(administratorAssociation, administratorAssociation.tempData?.firstName, administrator.firstName)
  }

  static getTemporaryOrRealLastName(administrator: AdministratorDTO, administratorAssociation: AdministratorAssociationDTO): string | undefined {
    return this.getTemporaryOrRealData(administratorAssociation, administratorAssociation.tempData?.lastName, administrator.lastName)
  }

  static getTemporaryOrRealName(administrator: AdministratorDTO, administratorAssociation: AdministratorAssociationDTO): string {
    return this.getTemporaryOrRealFirstName(administrator, administratorAssociation)
      + ' '
      + AdministratorUtils.getTemporaryOrRealLastName(administrator, administratorAssociation)
  }

}
