import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {createModel, HomePageModel} from "./model";
import {RootState} from "../../../store/store";
import {DrawDTO, drawsApi} from "../../../apis/draws-api";
import {AdministratorDTO, administratorsApi} from "../../../apis/administrators-api";
import {TeamDTO, teamsApi} from "../../../apis/teams-api";
import {AssociationDrawStatsReport, AssociationDTO, associationsApi} from "../../../apis/associations-api";
import {PageResponse} from "../../../apis/core/api-base";
import {DateUtils} from '../../../utils/DateUtils';

interface HomePageState {
  isLoading: boolean
  loadingError: ErrorObject | null
  model: HomePageModel | null
  isUpdating: boolean
}

const initialState: HomePageState = {
  isLoading: false,
  loadingError: null,
  model: null,
  isUpdating: false
}

export const fetchData = createAsyncThunk<[TeamDTO, AssociationDTO, AdministratorDTO, PageResponse<DrawDTO>, PageResponse<DrawDTO>, PageResponse<DrawDTO>, PageResponse<AdministratorDTO>, AssociationDrawStatsReport[], number], void>(
  'home/fetchData',
  async (payload, thunkApi) => {

    // We need to load:
    // 1. Current team information
    // 2. Current association information
    // 3. Last completed draw
    // 4. Currently active draw
    // 5. Pending draws (because of count)
    // 6. List of administrators
    // 7. Configuration
    // 8. Number of supporters

    const rootState = thunkApi.getState() as RootState
    const settings = rootState.settings
    const teamId = settings.selectedTeam!.id
    const associationId = settings.selectedAssociation!.id
    const periodValue = DateUtils.getSeasonStartYear(settings.selectedTeam!.startMonth)
    const [fromIso, toIso] = DateUtils.createFromToIso('SEASON', `${periodValue}`, undefined, settings.selectedTeam!.startMonth)

    return Promise.all([
      settings.selectedTeam!,
      settings.selectedAssociation!,
      settings.user!,
      // Last completed draw
      drawsApi.getDraws(0, 1, {teamId, status: 'CLOSED', sortBy: 'salesEnd', sortDirection: 'DESC', teamInfo: true}),
      // Currently active match draw
      drawsApi.getDraws(0, 1, {
        teamId,
        status: 'ACTIVE',
        sortBy: 'salesEnd',
        sortDirection: 'ASC',
        type: 'MATCH',
        teamInfo: true
      }),
      // Currently active event draw
      drawsApi.getDraws(0, 1, {
        teamId,
        status: 'ACTIVE',
        sortBy: 'salesEnd',
        sortDirection: 'ASC',
        type: 'EVENT',
        teamInfo: true
      }),
      // List of administrators
      administratorsApi.getAdministrators(0, 1_000, {associationId, status: 'CONFIRMED'}),
      associationsApi.getDrawStats(associationId, 'MONTHLY', teamId, fromIso, toIso),
      teamsApi.getNumberOfFavoritesPlayers(settings.selectedTeam!.id)
    ])
  },
)

export const updateDraw = createAsyncThunk<void, { drawId: string, salesStart?: string, salesEnd?: string }>(
  'home/updateDraw',
  async (payload, _thunkApi) => {
    return drawsApi.updateDrawWithNotifications(payload.drawId, payload.salesStart, payload.salesEnd)
  }
)

export const membersSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    initPage: (_state) => {
      // TODO
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      const [team, association, currentAdministrator, closedDrawsPage, activeMatchDrawsPage, activeEventDrawsPage, administratorsPage, drawStats, numberOfFavorites] = action.payload
      state.model = createModel(team, association, closedDrawsPage, activeMatchDrawsPage, activeEventDrawsPage, administratorsPage, drawStats, currentAdministrator, numberOfFavorites)
      state.isLoading = false
    })

    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

    builder.addCase(updateDraw.pending, (state) => {
      state.isUpdating = true
    })

    builder.addCase(updateDraw.fulfilled, (state, action) => {
      const {drawId, salesStart, salesEnd} = action.meta.arg

      const matchDraw = state.model!.nextMatchDraw && state.model!.nextMatchDraw.id === drawId ? state.model!.nextMatchDraw : undefined

      if (matchDraw) {
        if (salesStart)
          matchDraw.startTime = salesStart

        if (salesEnd)
          matchDraw.closingTime = salesEnd
      }

      const eventDraw = state.model!.nextEventDraw && state.model!.nextEventDraw.id === drawId ? state.model!.nextEventDraw : undefined

      if (eventDraw) {
        if (salesStart)
          eventDraw.startTime = salesStart
        if (salesEnd)
          eventDraw.closingTime = salesEnd
      }

      state.isUpdating = false

    })

    builder.addCase(updateDraw.rejected, (state) => {
      state.isUpdating = false
    })

  },
})

// Actions
export const {initPage} = membersSlice.actions

// Selectors
export const selectModel = (state: RootState) => state.home.model
export const selectIsLoading = (state: RootState) => state.home.isLoading
export const selectLoadingError = (state: RootState) => state.home.loadingError
export const selectIsUpdating = (state: RootState) => state.home.isUpdating

export default membersSlice.reducer
