import {AdministratorAssociationDTO, AdministratorDTO} from "../../../apis/administrators-api";
import {AssociationDTO} from "../../../apis/associations-api";

export type MyAccountPageModel = {
  userDTO: AdministratorDTO
  administratorAssociation: AdministratorAssociationDTO
}

export const createModel = (user: AdministratorDTO, association: AssociationDTO): MyAccountPageModel => {

  const administratorAssociation = user.associations?.find(it => it.association.id === association.id)!

  return {
    userDTO: user,
    administratorAssociation
  }
}
