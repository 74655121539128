import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";
import {createModel, DrawAddPageModel} from "./model";
import {TeamDTO, teamsApi} from "../../../apis/teams-api";
import {DrawType} from "../../../utils/AssociationTypes";
import {configurationApi, ConfigurationDTO} from "../../../apis/configuration-api";

export interface MatchData {
  matchIndex: number
  opponentTeam: TeamDTO
  isHomeGame: boolean
  startDate?: string
  endDate?: string
  price?: number
  isCompleted: boolean
}

interface DrawAddState {
  isLoading: boolean
  loadingError: ErrorObject | null,
  model: DrawAddPageModel | null,
  selectedWizardStep: number
  selectedDrawType: DrawType
  eventName: string
  eventDescription: string
  ticketPrice: number
  eventStartDate: string
  eventEndDate: string
  currentMatchIndex: number
  matches: MatchData[]
  editedMatch?: MatchData | null
}

const initialState: DrawAddState = {
  isLoading: false,
  loadingError: null,
  model: null,
  selectedWizardStep: 0,
  selectedDrawType: 'MATCH',
  eventName: '',
  eventDescription: '',
  ticketPrice: 0,
  eventStartDate: '',
  eventEndDate: '',
  currentMatchIndex: 0,
  matches: [],
  editedMatch: null
}

/**
 * When opening this page we need:
 * - list of all possible opponent teams
 */
export const fetchData = createAsyncThunk<[TeamDTO[], ConfigurationDTO]>(
  'drawAdd/fetchData',
  async (payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState
    const selectedTeam = rootState.settings.selectedTeam!
    return Promise.all([
      teamsApi.searchAll({
        categoryId: selectedTeam.category.id,
        sportId: selectedTeam.sport.id,
        gender: selectedTeam.gender
      }),
      configurationApi.getConfiguration()
    ])
  },
)

export const drawAddSlice = createSlice({
  name: 'drawAdd',
  initialState,
  reducers: {
    initPage: (state) => {
      state.model = null
      state.selectedWizardStep = 0
      state.selectedDrawType = 'MATCH'
      state.eventName = ''
      state.eventDescription = ''
      state.ticketPrice = 0
      state.eventStartDate = ''
      state.eventEndDate = ''
      state.currentMatchIndex = 0
      state.matches = []
      state.editedMatch = null

    },
    selectDrawType: (state, action: PayloadAction<DrawType>) => {
      state.selectedDrawType = action.payload
    },
    selectNextStep: (state) => {
      state.selectedWizardStep = state.selectedWizardStep + 1
    },
    selectPreviousStep: (state) => {
      // Here we need to check what exactly is a previous step depending on where we are:
      // If user is editing a match, going back works differently and depends on current match step
      if (state.editedMatch) {
        if (state.selectedWizardStep === 1) {
          state.editedMatch = null
          state.currentMatchIndex = state.matches.length - 1
          state.selectedWizardStep = 3
        } else {
          state.selectedWizardStep = state.selectedWizardStep - 1
        }
      } else {
        // If we are on a review step and click on Back, we are going to previous step with matchIndex set to last match
        if(state.selectedDrawType === 'MATCH' && state.selectedWizardStep === 3) {
          state.selectedWizardStep = 2
          state.currentMatchIndex = state.matches.length - 1
        } else
        // if user is on any screen for non first match draw, going back means going back to dates step
        // of a previous match
        if (state.selectedDrawType === 'MATCH' && state.currentMatchIndex > 0) {
          // If we are on a dates/prices step, we go back one step, like the usual,
          // if we are on match choosing step, we go forward one step and decrement match index,
          // and we also remove the last match from the array of added matches as we cannot go back to it again
          if (state.selectedWizardStep === 1) {
            state.selectedWizardStep = state.selectedWizardStep + 1
            state.currentMatchIndex = state.currentMatchIndex - 1
            // If we already added match at current match index, we need to remove it
            if (state.matches.length > state.currentMatchIndex + 1) {
              state.matches.pop()
            }
          } else {
            state.selectedWizardStep = state.selectedWizardStep - 1
          }
        } else {
          state.selectedWizardStep = state.selectedWizardStep - 1
        }
      }
    },
    setEventNameAndDescription: (state, action: PayloadAction<{ name: string, description: string }>) => {
      state.eventName = action.payload.name
      state.eventDescription = action.payload.description
    },
    setEventPriceAndDates: (state, action: PayloadAction<{ price: number, startDate: string, endDate: string }>) => {
      state.ticketPrice = action.payload.price
      state.eventStartDate = action.payload.startDate
      state.eventEndDate = action.payload.endDate
    },
    setMatchTeam: (state, action: PayloadAction<{ opponentTeamId: string, isHomeGame: boolean, matchIndex: number }>) => {
      const {opponentTeamId, isHomeGame, matchIndex} = action.payload
      // We might have an existing match at this index if we are moving back through multiple matches
      const existingMatch = state.matches[matchIndex] || {}
      state.matches[matchIndex] = {
        ...existingMatch,
        opponentTeam: state.model!.teams.find(it => it.id === opponentTeamId)!,
        isHomeGame,
        matchIndex,
        isCompleted: existingMatch.isCompleted || false
      }
    },
    setMatchPriceAndDates: (state, action: PayloadAction<{ price: number, startDate: string, endDate: string, matchIndex: number }>) => {
      const {matchIndex, endDate, startDate, price} = action.payload
      state.matches[matchIndex] = {
        ...state.matches[matchIndex],
        startDate,
        endDate,
        price,
        isCompleted: true
      }

      // If we are editing a draw, we must also make sure the start date of the next draw is in sync with the end draw of this draw
      const isLastDraw = state.matches.length === matchIndex + 1

      if(!isLastDraw) {
        state.matches[matchIndex + 1].startDate = endDate
      }

      // Maybe we were here by editing, if so we need to reset editing state
      state.editedMatch = null
    },
    addMatch: (state) => {
      const newMatchIndex = state.matches.length
      state.currentMatchIndex = newMatchIndex
      state.selectedWizardStep = 1

      // We must also rewrite previous match endDate to be the next match startDate
      const newMatch = state.matches[newMatchIndex] || {}
      const previousMatch = state.matches[newMatchIndex - 1]
      state.matches[newMatchIndex] = {
        ...newMatch,
        startDate: previousMatch.endDate,
        isHomeGame: true,
      }

      // Maybe we pressed on add match button while in edit mode, if so, reset the edit mode
      state.editedMatch = null
    },
    editMatch: (state, action: PayloadAction<{ match: MatchData }>) => {
      //When starting editing a match we basically do next:
      // set edited match
      // go back to wizard second step for this particular match
      const {match} = action.payload
      state.editedMatch = match
      state.currentMatchIndex = match.matchIndex
      state.selectedWizardStep = 1
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      const [teams, configuration] = action.payload
      state.isLoading = false
      state.model = createModel(teams, configuration)
    })

    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

  },
})

// Actions
export const {
  initPage,
  selectDrawType,
  selectNextStep,
  selectPreviousStep,
  setEventNameAndDescription,
  setEventPriceAndDates,
  setMatchTeam,
  setMatchPriceAndDates,
  addMatch,
  editMatch
} = drawAddSlice.actions

// Selectors
export const selectIsLoading = (state: RootState) => state.drawAdd.isLoading
export const selectLoadingError = (state: RootState) => state.drawAdd.loadingError
export const selectModel = (state: RootState) => state.drawAdd.model
export const selectSelectedWizardStep = (state: RootState) => state.drawAdd.selectedWizardStep
export const selectSelectedDrawType = (state: RootState) => state.drawAdd.selectedDrawType
export const selectEventName = (state: RootState) => state.drawAdd.eventName
export const selectEventDescription = (state: RootState) => state.drawAdd.eventDescription
export const selectTicketPrice = (state: RootState) => state.drawAdd.ticketPrice
export const selectEventStartDate = (state: RootState) => state.drawAdd.eventStartDate
export const selectEventEndDate = (state: RootState) => state.drawAdd.eventEndDate
export const selectCurrentMatchIndex = (state: RootState) => state.drawAdd.currentMatchIndex
export const selectMatches = (state: RootState) => state.drawAdd.matches
export const selectEditedMatch = (state: RootState) => state.drawAdd.editedMatch

export default drawAddSlice.reducer
