import {AssociationDrawStatsReport, AssociationDTO, MonthlyReport} from "../../../apis/associations-api";
import {Price} from "../../../utils/AssociationTypes";
import {TeamDTO} from "../../../apis/teams-api";
import moment from "moment/moment";
import {DateUtils, PeriodType} from "../../../utils/DateUtils";

const START_OF_TIME_MOMENT = moment('2022-01-01')
const MAX_NUMBER_OF_MONTH_REPORTS = 24

export type StatisticsReportType = 'team' | 'association'

export type StatisticsColumnChartItem = {
  ticketsCount: number
}

export type ColumnChartLabel = {
  value: number
  name: string
}

export type StatisticsPageModel = {
  association: AssociationDTO,
  totalSum: Price,
  totalPlayers: number
  totalTickets: number
  columnChartItems: StatisticsColumnChartItem[]
  seasonPeriodValues: string[]
  yearPeriodValues: string[]
  monthPeriodValues: string[]
  monthReportValues: string[]
  weekPeriodValues: string[]
  initialSeasonPeriodValue: string
  initialYearPeriodValue: string
  initialMonthPeriodValue: string
  initialWeekPeriodValue: string
  initialDay: string
  columnChartLabels: ColumnChartLabel[]
  monthlyReports?: MonthlyReport[]
}

export function createModel(
  teamDto: TeamDTO,
  associationDto: AssociationDTO,
  periodType: PeriodType,
  periodValue: string,
  day: string | undefined,
  reportDtos: AssociationDrawStatsReport[],
  monthlyReports: MonthlyReport[]
): StatisticsPageModel {

  let totalSum = 0, totalTickets = 0, totalPlayers = 0

  if (reportDtos.length) {
    totalSum = reportDtos[0].summary.associationContribution.amount
    totalTickets = reportDtos[0].summary.numberOfRows
    totalPlayers = reportDtos[0].summary.numberOfUsers
  }

  const currency = reportDtos.length > 0 ? reportDtos[0].stats.buy.currency : 'SEK'

  const columnChartLabels = createColumnChartLabels(periodType, periodValue, day, teamDto)
  const columnChartItems = createColumnChartItems(periodType, periodValue, day, reportDtos, columnChartLabels, teamDto)

  const seasonPeriodValues = createSeasonPeriodValues(teamDto)
  const yearPeriodValues = createYearPeriodValues()
  const monthPeriodValues = createMonthPeriodValues()
  const monthReportValues = createMonthReportValues(monthlyReports)
  const weekPeriodValues = createWeekPeriodValues()

  const nowMoment = moment()
  const nowYear = nowMoment.year()
  const nowMonth = nowMoment.month()
  const nowWeek = nowMoment.week()

  const initialSeasonPeriodValue = DateUtils.getSeasonStartYear(teamDto.startMonth) + ''
  const initialYearPeriodValue = `${nowYear}`
  const initialMonthPeriodValue = `${nowYear}-${nowMonth}`
  const initialWeekPeriodValue = `${nowYear}-${nowWeek}`
  const initialDay = nowMoment.format('YYYY-MM-DD')

  const model: StatisticsPageModel = {
    association: associationDto,
    totalSum: {amount: totalSum, currency},
    totalPlayers,
    totalTickets,
    columnChartLabels,
    columnChartItems,
    seasonPeriodValues,
    yearPeriodValues,
    monthPeriodValues,
    monthReportValues,
    weekPeriodValues,
    initialSeasonPeriodValue,
    initialYearPeriodValue,
    initialMonthPeriodValue,
    initialWeekPeriodValue,
    initialDay,
    monthlyReports
  }

  return model
}

function createColumnChartItems(periodType: PeriodType, periodValue: string, selectedDay: string | undefined, reportDtos: AssociationDrawStatsReport[], columnChartLabels: ColumnChartLabel[], team: TeamDTO): StatisticsColumnChartItem[] {

  let items: StatisticsColumnChartItem[] = []

  for (let i = 0; i < columnChartLabels.length; i++) {

    let dtos: AssociationDrawStatsReport[] = []

    if (periodType === 'SEASON') {
      const startMonth = team.startMonth
      const year = +periodValue
      const month = columnChartLabels[i].value

      dtos = reportDtos.filter(it => {
        const m = moment(it.time)

        // If season starts after January, for previous months we are looking into the next year
        if (startMonth > 1 && month < startMonth - 1)
          return m.year() === (year + 1) && m.month() === month
        else
          return m.year() === year && m.month() === month
      })
    } else if (periodType === 'YEAR') {
      const year = +periodValue
      const month = columnChartLabels[i].value

      dtos = reportDtos.filter(it => {
        const m = moment(it.time)

        return m.year() === year && m.month() === month
      })
    } else if (periodType === 'MONTH') {
      const parts = periodValue.split('-')
      const year = +parts[0]
      const month = +parts[1]
      let weekInYear = columnChartLabels[i].value

      // Current week number in year might be over the limit i.e. it starts in next year
      const numOfWeeksInYear = moment().year(year).weeksInYear()

      if (weekInYear > numOfWeeksInYear)
        weekInYear = 1

      dtos = reportDtos.filter(it => {
        const m = moment(it.time)

        return m.year() === year && m.month() === month && m.week() === weekInYear
      })
    } else if (periodType === 'WEEK') {
      const parts = columnChartLabels[i].name.split('-')
      const year = +parts[0]
      const dayOfYear = +parts[1]

      dtos = reportDtos.filter(it => {
        const m = moment(it.time)

        return m.year() === year && m.dayOfYear() === dayOfYear
      })
    } else if (periodType === 'DAY') {
      const hour = columnChartLabels[i].value

      dtos = reportDtos.filter(it => {
        const m = moment(it.time)

        return m.hour() === hour
      })
    }

    let numberOfTickets = 0

    dtos.forEach(it => {
      numberOfTickets += it.stats.numberOfRows
    })

    items.push({
      ticketsCount: numberOfTickets
    })
  }

  return items
}

function createColumnChartLabels(selectedPeriodType: PeriodType, selectedPeriodValue: string, day: string | undefined, team: TeamDTO): ColumnChartLabel[] {

  let labels: ColumnChartLabel[] = []

  if (selectedPeriodType === 'SEASON') {

    const startMonth = team.startMonth

    for (let i = 0; i < 12; i++) {

      // Rotate months after year ends if start month is not January
      const monthNumber = (i + startMonth - 1) % 12

      labels.push({
        value: monthNumber,
        name: monthNumber + ''
      })
    }
  } else if (selectedPeriodType === 'YEAR') {

    for (let monthNumber = 0; monthNumber < 12; monthNumber++) {

      labels.push({
        value: monthNumber,
        name: monthNumber + ''
      })
    }
  } else if (selectedPeriodType === 'MONTH') {
    const [yearPart, monthPart] = selectedPeriodValue.split('-')
    const year = +yearPart
    const month = +monthPart

    const weeksOfMonth = DateUtils.getWeeksOfMonth(year, month)

    labels = weeksOfMonth.map(it => ({
        value: it.weekInYear,
        name: it.firstDate + '-' + it.lastDate,
      })
    )
  } else if (selectedPeriodType === 'WEEK') {
    const [yearPart, weekPart] = selectedPeriodValue.split('-')
    const year = +yearPart
    const week = +weekPart

    const daysOfWeek = DateUtils.getDaysOfWeek(year, week)

    labels = daysOfWeek.map(it => ({
      value: it.dayOfYear,
      name: it.year + '-' + it.dayOfYear
    }))
  } else if (selectedPeriodType === 'DAY') {

    for (let i = 0; i < 24; i++) {
      labels.push({
        value: i,
        name: i + ''
      })
    }
  }

  return labels
}

/**
 * Create list of season values where season month is year.
 * List starts at certain start date and goes all the way to current year.
 * List is reversed so the latest season is at the top.
 */
function createSeasonPeriodValues(_team: TeamDTO) {

  // const seasonStartMonth = team.startMonth || 1

  const nowMoment = moment()

  let startYear = START_OF_TIME_MOMENT.year()
  let endYear = nowMoment.year()

  const periodValues = []

  let iterationYear = startYear

  while (true) {

    if (iterationYear > endYear)
      break

    const value = `${iterationYear}`

    periodValues.push(value)

    iterationYear++
  }

  periodValues.reverse()

  return periodValues
}

/**
 * Create list of year values.
 * List starts at certain start date and goes all the way to current year.
 * List is reversed so most recent year is at the top.
 */
function createYearPeriodValues() {

  const nowMoment = moment()

  let startYear = START_OF_TIME_MOMENT.year()
  let endYear = nowMoment.year()

  const periodValues = []

  let iterationYear = startYear

  while (true) {

    if (iterationYear > endYear)
      break

    const value = `${iterationYear}`

    periodValues.push(value)

    iterationYear++
  }

  periodValues.reverse()

  return periodValues
}

/**
 * Create list of month values where each month is a combination of year and month of year.
 * List starts at certain start date and goes all the way to current month of current year.
 * List is reversed so latest month is at the top.
 */
function createMonthPeriodValues() {

  const nowMoment = moment()

  const startYear = START_OF_TIME_MOMENT.year()
  const startMonth = START_OF_TIME_MOMENT.month()
  const endYear = nowMoment.year()
  const endMonth = nowMoment.month()

  const periodValues = []

  let iterationYear = startYear
  let iterationMonth = startMonth

  while (true) {

    if (iterationYear > endYear || (iterationYear === endYear && iterationMonth > endMonth))
      break

    const value = `${iterationYear}-${iterationMonth}`

    periodValues.push(value)

    iterationMonth++

    if (iterationMonth > 11) {
      iterationMonth = 0
      iterationYear++
    }
  }

  periodValues.reverse()

  return periodValues
}

function createMonthReportValues(monthlyReports: MonthlyReport[]): string[] {

  if (!monthlyReports || !monthlyReports.length)
    return []

  // Copy as provided value is immutable so inplace sort won't work
  const monthlyReportsCopy = [...monthlyReports]

  monthlyReportsCopy.sort((a, b) => {
    const aWeight = a.year * 1000 + a.month
    const bWeight = b.year * 1000 + b.month

    return bWeight - aWeight
  })

  const periodValues: string[] = []

  for(let i = 0; i < monthlyReportsCopy.length; i++) {

    // We show up to 24 values in the dropdown
    if(i >= MAX_NUMBER_OF_MONTH_REPORTS)
      break

    const report = monthlyReportsCopy[i]

    const value = `${report.year}-${String(report.month - 1).padStart(2, '0')}`

    periodValues.push(value)
  }

  return periodValues
}

/**
 * Create list of week values where each week is a combination of year and week of year.
 * List starts at certain start date and goes all the way to current week of current year.
 * List is reversed so latest week is at the top.
 */
function createWeekPeriodValues() {

  const nowMoment = moment()

  const startYear = START_OF_TIME_MOMENT.year()
  const startWeek = START_OF_TIME_MOMENT.week()
  const endYear = nowMoment.year()
  const endWeek = nowMoment.week()

  const periodValues = []

  let iterationYear = startYear
  let iterationWeek = startWeek

  while (true) {

    const numberOfWeeksInIterationYear = moment().year(iterationYear).weeksInYear()

    if (iterationYear > endYear
      || iterationWeek > numberOfWeeksInIterationYear
      || (iterationYear === endYear && iterationWeek > endWeek)
    )
      break

    const value = `${iterationYear}-${iterationWeek}`

    periodValues.push(value)

    iterationWeek++

    if (iterationWeek > numberOfWeeksInIterationYear) {
      iterationWeek = 1
      iterationYear++
    }
  }

  periodValues.reverse()

  return periodValues
}
