import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {createModel, StatisticsPageModel, StatisticsReportType} from "./model";
import {RootState} from "../../../store/store";
import {TeamDTO} from "../../../apis/teams-api";
import {
  AssociationDrawStatsReport,
  AssociationDTO,
  associationsApi, MonthlyReport,
  ReportPeriodType
} from "../../../apis/associations-api";
import {DateUtils, PeriodType} from '../../../utils/DateUtils';
import moment from "moment";

type ReportDownloadStatus = {
  isDownloading: boolean
  error: ErrorObject | null
}
interface StatisticsPageState {
  isLoading: boolean
  loadingError: ErrorObject | null
  model: StatisticsPageModel | null
  selectedPeriodType: PeriodType,
  selectedPeriodValue: string,
  selectedReportMonthValue: string,
  selectedReportType: StatisticsReportType
  selectedDay?: string
  lastSelectedSeasonPeriodValue: string | undefined
  lastSelectedYearPeriodValue: string | undefined
  lastSelectedMonthPeriodValue: string | undefined
  lastSelectedWeekPeriodValue: string | undefined
  lastSelectedDayPeriodValue: string | undefined
  isInitialLoading: boolean
  reportDownloadStatus: ReportDownloadStatus
}

const initialState: StatisticsPageState = {
  isLoading: false,
  loadingError: null,
  model: null,
  selectedPeriodType: 'SEASON',
  selectedPeriodValue: '',
  selectedReportMonthValue: '',
  selectedReportType: 'team',
  selectedDay: undefined,
  lastSelectedSeasonPeriodValue: undefined,
  lastSelectedYearPeriodValue: undefined,
  lastSelectedMonthPeriodValue: undefined,
  lastSelectedWeekPeriodValue: undefined,
  lastSelectedDayPeriodValue: undefined,
  isInitialLoading: false,
  reportDownloadStatus: {
    isDownloading: false,
    error: null
  }
}

export const fetchData = createAsyncThunk<[TeamDTO, AssociationDTO, AssociationDrawStatsReport[], MonthlyReport[]], boolean>(
  'statistics/fetchData',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState
    const settings = rootState.settings
    const store = rootState.statistics
    const associationId = settings.selectedAssociation!.id
    const teamId = settings.selectedTeam!.id

    let periodType: ReportPeriodType

    if (store.selectedPeriodType === 'DAY')
      periodType = 'HOURLY'
    else if (store.selectedPeriodType === 'SEASON' || store.selectedPeriodType === 'YEAR')
      periodType = 'MONTHLY'
    else
      periodType = 'DAILY'

    let periodValue = store.selectedPeriodValue

    // If we are initially on season and season is not selected yet, select the season depending on
    // its start month for currently selected team, it's either current year season or last year season
    if (!store.selectedPeriodValue && store.selectedPeriodType === 'SEASON') {
      periodValue = DateUtils.getSeasonStartYear(settings.selectedTeam!.startMonth) + ''
      thunkApi.dispatch(setPeriodValue(periodValue))
    }

    const [fromIso, toIso] = DateUtils.createFromToIso(store.selectedPeriodType, periodValue, store.selectedDay, settings.selectedTeam!.startMonth)

    return Promise.all([
      settings.selectedTeam!,
      settings.selectedAssociation!,
      associationsApi.getDrawStats(associationId, periodType, store.selectedReportType === 'team' ? teamId : undefined, fromIso, toIso),
      // If we have loaded monthly reports already, no need to load them again since they change only once a month
      store.model?.monthlyReports ? store.model?.monthlyReports : associationsApi.listMonthlyReports(associationId)
    ])
  },
)

export const downloadReport = createAsyncThunk<void>(
  'statistics/downloadReport',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState
    const settings = rootState.settings
    const associationId = settings.selectedAssociation!.id
    const store = rootState.statistics

    try {
      thunkApi.dispatch(setReportDownloadStatus({isDownloading: true, error: null}))

      const [yearString, monthString] = store.selectedReportMonthValue.split('-')
      // Option values have a zero based month number but report name is one based month number
      const formattedYearMonth = yearString + ((+monthString + 1) + "").padStart(2, '0')
      await associationsApi.downloadMonthlyReport(associationId, formattedYearMonth)

      thunkApi.dispatch(setReportDownloadStatus({isDownloading: false, error: null}))
    } catch (error) {
      const errorObject = ErrorUtils.createErrorObject(error)
      thunkApi.dispatch(setReportDownloadStatus({error: errorObject, isDownloading: false}))
    }
  }
)

export const selectChartItem = createAsyncThunk<void, number>(
  'statistics/selectChartItem',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState
    const store = rootState.statistics
    const settings = rootState.settings

    const chartLabel = store.model!.columnChartLabels[payload]

    // Here the goal is to go from one report period type into a more detailed one with updated period values
    if (store.selectedPeriodType === 'YEAR') {

      const requestedMonth = chartLabel.value // Starting from zero for January
      const requestedYear = store.selectedPeriodValue
      const newPeriodValue = requestedYear + '-' + requestedMonth
      thunkApi.dispatch(setPeriodType('MONTH'))
      thunkApi.dispatch(setPeriodValue(newPeriodValue))
      thunkApi.dispatch(fetchData(false))

    } else if (store.selectedPeriodType === 'SEASON') {

      const requestedMonth = chartLabel.value // Starting from zero for January
      let requestedYear = +store.selectedPeriodValue

      if (settings.selectedTeam!.startMonth > 1 && requestedMonth < settings.selectedTeam!.startMonth - 1)
        requestedYear++

      const newPeriodValue = requestedYear + '-' + requestedMonth
      thunkApi.dispatch(setPeriodType('MONTH'))
      thunkApi.dispatch(setPeriodValue(newPeriodValue))
      thunkApi.dispatch(fetchData(false))

    } else if (store.selectedPeriodType === 'MONTH') {

      // From month view we are selecting a particular week so we are moving down to week view
      let requestedYear = +store.selectedPeriodValue.split('-')[0]
      let requestedWeek = chartLabel.value
      const weeksInYear = moment().year(requestedYear).weeksInYear()

      if (requestedWeek > weeksInYear) {
        requestedYear++
        requestedWeek = 1
      }

      const newPeriodValue = requestedYear + '-' + requestedWeek
      thunkApi.dispatch(setPeriodType('WEEK'))
      thunkApi.dispatch(setPeriodValue(newPeriodValue))
      thunkApi.dispatch(fetchData(false))

    } else if (store.selectedPeriodType === 'WEEK') {

      // From week view we are selecting a particular year
      const requestedYear = +store.selectedPeriodValue.split('-')[0]
      const requestedDayOfYear = chartLabel.value
      const newPeriodValue = moment().year(requestedYear).dayOfYear(requestedDayOfYear).format('YYYY-MM-DD')
      thunkApi.dispatch(setPeriodType('DAY'))
      thunkApi.dispatch(setSelectedDay(newPeriodValue))
      thunkApi.dispatch(fetchData(false))

    }
  })

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    initPage: (state, action: PayloadAction<TeamDTO>) => {
      state.isLoading = false
      state.loadingError = null
      state.lastSelectedYearPeriodValue = undefined
      state.lastSelectedSeasonPeriodValue = undefined
      state.lastSelectedMonthPeriodValue = undefined
      state.lastSelectedWeekPeriodValue = undefined
      state.lastSelectedDayPeriodValue = undefined
      state.reportDownloadStatus = {isDownloading: false, error: null}

      const now = moment()
      const monthAgo = now.subtract(1, 'months')
      state.selectedReportMonthValue = `${monthAgo.year()}-${monthAgo.month().toString().padStart(2, '0')}`

      // If we have loaded monthly reports and we have changed currently selected association, we need to reset
      // loaded monthly reports
      if(state.model) {
        const monthlyReportsLoaded = !!state.model.monthlyReports
        const associationChanged = state.model.association.id !== action.payload.association?.id
        if (monthlyReportsLoaded && associationChanged)
          state.model.monthlyReports = undefined
      }
    },
    setPeriodType: (state, action: PayloadAction<PeriodType>) => {

      // First we store existing period value
      if (state.selectedPeriodType === 'SEASON')
        state.lastSelectedSeasonPeriodValue = state.selectedPeriodValue
      else if (state.selectedPeriodType === 'YEAR')
        state.lastSelectedYearPeriodValue = state.selectedPeriodValue
      else if (state.selectedPeriodType === 'MONTH')
        state.lastSelectedMonthPeriodValue = state.selectedPeriodValue
      else if (state.selectedPeriodType === 'WEEK')
        state.lastSelectedWeekPeriodValue = state.selectedPeriodValue
      else if (state.selectedPeriodType === 'DAY')
        state.lastSelectedDayPeriodValue = state.selectedDay

      state.selectedPeriodType = action.payload

      if (action.payload === 'SEASON') {
        state.selectedPeriodValue = state.lastSelectedSeasonPeriodValue || state.model!.initialSeasonPeriodValue
      } else if (action.payload === 'YEAR') {
        state.selectedPeriodValue = state.lastSelectedYearPeriodValue || state.model!.initialYearPeriodValue
      } else if (action.payload === 'MONTH') {
        state.selectedPeriodValue = state.lastSelectedMonthPeriodValue || state.model!.initialMonthPeriodValue
      } else if (action.payload === 'WEEK') {
        state.selectedPeriodValue = state.lastSelectedWeekPeriodValue || state.model!.initialWeekPeriodValue
      } else if (action.payload === 'DAY') {
        state.selectedDay = state.lastSelectedDayPeriodValue || state.model!.initialDay
      }
    },
    setPeriodValue: (state, action: PayloadAction<string>) => {
      state.selectedPeriodValue = action.payload
    },
    setReportMonthValue: (state, action: PayloadAction<string>) => {
      state.selectedReportMonthValue = action.payload
    },
    setReportType: (state, action: PayloadAction<StatisticsReportType>) => {
      state.selectedReportType = action.payload

      // If we are switching to association report type, and we were on season period type,
      // we must switch to year period type as season is not applicable to association
      if (state.selectedReportType === 'association' && state.selectedPeriodType === 'SEASON') {
        state.selectedPeriodType = 'YEAR'
        state.selectedPeriodValue = state.model!.initialYearPeriodValue
      }
    },
    setSelectedDay: (state, action: PayloadAction<string>) => {
      state.selectedDay = action.payload
    },
    setReportDownloadStatus: (state, action: PayloadAction<{ isDownloading: boolean, error: ErrorObject | null }>) => {
      state.reportDownloadStatus.isDownloading = action.payload.isDownloading
      state.reportDownloadStatus.error = action.payload.error
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state, action) => {
      state.isLoading = true
      state.isInitialLoading = action.meta.arg
      state.loadingError = null
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      const [team, association, drawStats, monthlyReports] = action.payload
      state.model = createModel(team, association, state.selectedPeriodType, state.selectedPeriodValue, state.selectedDay, drawStats, monthlyReports)

      // We need to check if currently selected report month is available option, if not, change the selection to
      // last available month
      const isCurrentReportMonthAvailable = state.model.monthReportValues.includes(state.selectedReportMonthValue)

      if(!isCurrentReportMonthAvailable) {
        if(state.model.monthReportValues.length) {
          state.selectedReportMonthValue = state.model.monthReportValues[0]
        } else {
          state.selectedReportMonthValue = ''
        }
      }

      state.isLoading = false
    })

    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

  },
})

// Actions
export const {initPage, setPeriodType, setPeriodValue, setReportMonthValue, setReportType, setSelectedDay, setReportDownloadStatus} = statisticsSlice.actions

// Selectors
export const selectModel = (state: RootState) => state.statistics.model
export const selectIsLoading = (state: RootState) => state.statistics.isLoading
export const selectIsInitialLoading = (state: RootState) => state.statistics.isInitialLoading
export const selectLoadingError = (state: RootState) => state.statistics.loadingError
export const selectSelectedPeriodType = (state: RootState) => state.statistics.selectedPeriodType
export const selectSelectedPeriodValue = (state: RootState) => state.statistics.selectedPeriodValue
export const selectReportMonthValue = (state: RootState) => state.statistics.selectedReportMonthValue
export const selectSelectedReportType = (state: RootState) => state.statistics.selectedReportType
export const selectSelectedDay = (state: RootState) => state.statistics.selectedDay
export const selectReportDownloadStatus = (state: RootState) => state.statistics.reportDownloadStatus

export default statisticsSlice.reducer
