import {DrawDTO, DrawStatsDTO} from "../apis/draws-api";
import {Price} from "./AssociationTypes";

export class DrawUtils {

  static calculateTotalTickets(draw: DrawDTO): number {

    return this.getDrawStats(draw).totalRows || 0
  }

  static calculateTotalPlayers(draw: DrawDTO): number {

    return this.getDrawStats(draw).totalPlayers || 0
  }

  // to-do: check if jackpot can be pulled from somewhere else and if fallback is ok
  static calculateJackpotSize(draw: DrawDTO): Price {

    return {
      amount: this.getDrawStats(draw).poolSize?.amount || 0,
      currency: draw.price.currency,
    }
  }

  static calculateAssociationIncome(draw: DrawDTO): Price {

    return {
      amount: this.getDrawStats(draw).associationIncome?.amount || 0,
      currency: draw.price.currency,
    }
  }

  static calculatePlayerIncome(draw: DrawDTO): Price {

    return {
      amount: this.getDrawStats(draw).poolSize?.amount || 0,
      currency: draw.price.currency,
    }
  }

  static getFormattedWinnerNumber(draw: DrawDTO): string | undefined {

    let winnerNumber: string | undefined

    const categories = draw.results?.prizeCategories

    if(categories && categories.length) {
      const winners = categories[0].winners

      if(winners && winners.length) {
        const winnerData = winners[0].winnerData

        if(winnerData) {
          winnerNumber = winnerData.number
        }
      }
    }

    if(winnerNumber)
      return (winnerNumber + '').padStart(6, '0')
  }

  private static getDrawStats(draw: DrawDTO): DrawStatsDTO {

    return draw.results && draw.results.stats ? draw.results.stats : {}
  }

}
