import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import en from './en'
import sv from './sv'

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'sv',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    ns: ['translations'],
    defaultNS: 'translations',
    resources: {
      en: en,
      sv: sv,
    }
  })

export default i18n
