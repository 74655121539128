import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {createModel, DrawsPageModel, DrawsTab,} from "./model";
import {RootState} from "../../../store/store";
import {TeamDTO} from "../../../apis/teams-api";
import {AssociationDTO} from "../../../apis/associations-api";
import {PageResponse} from "../../../apis/core/api-base";
import {DrawDTO, drawsApi, DrawSortDirection, DrawSortField} from "../../../apis/draws-api";
import {DrawStatus} from "../../../utils/AssociationTypes";

interface DrawsPageState {
  isLoading: boolean
  loadingError: ErrorObject | null
  model: DrawsPageModel | null
  selectedTab: DrawsTab
  currentPage: number
  pageSize: number
  isLoadingMore: boolean
  isUpdating: boolean
}

const initialState: DrawsPageState = {
  isLoading: false,
  loadingError: null,
  model: null,
  selectedTab: 'ACTIVE',
  currentPage: 0,
  pageSize: 6,
  isLoadingMore: false,
  isUpdating: false
}

export const fetchData = createAsyncThunk<[TeamDTO, AssociationDTO, PageResponse<DrawDTO>, PageResponse<DrawDTO>, PageResponse<DrawDTO>, PageResponse<DrawDTO>, boolean], boolean>(
  'draws/fetchData',
  async (payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState
    const settings = rootState.settings
    const teamId = settings.selectedTeam!.id
    const selectedTab = rootState.draws.selectedTab
    const currentPage = rootState.draws.currentPage
    const pageSize = rootState.draws.pageSize
    const isLoadMore = payload

    // Depending on currently selected tab we might load one or more draw collections
    const createDrawLoadingPromise = (drawStatus: DrawStatus, sortBy: DrawSortField, sortDirection: DrawSortDirection): Promise<PageResponse<DrawDTO>> => {

      if (selectedTab === drawStatus) {
        return drawsApi.getDraws(
          currentPage + (isLoadMore ? 1 : 0),
          pageSize,
          {teamId, status: drawStatus, teamInfo: true, sortBy, sortDirection})
      } else {
        const emptyResponse: PageResponse<DrawDTO> = {
          items: [],
          totalItems: 0,
          pageSize: 0,
          pageIndex: 0,
          totalPages: 0
        }

        return Promise.resolve(emptyResponse)
      }
    }

    return Promise.all([
      settings.selectedTeam!,
      settings.selectedAssociation!,
      createDrawLoadingPromise('ACTIVE', "salesEnd", "ASC"),
      createDrawLoadingPromise('PENDING', "salesStart", "ASC"),
      createDrawLoadingPromise('CLOSED', "salesEnd", "DESC"),
      createDrawLoadingPromise('CANCELLED', "salesEnd", "DESC"),
      isLoadMore
    ])
  },
)


export const updateDraw = createAsyncThunk<void, { drawId: string, salesStart?: string, salesEnd?: string }>(
  'draws/updateDraw',
  async (payload, _thunkApi) => {
    return drawsApi.updateDrawWithNotifications(payload.drawId, payload.salesStart, payload.salesEnd)
  }
)

export const drawsSlice = createSlice({
  name: 'draws',
  initialState,
  reducers: {
    initPage: (state, action: PayloadAction<DrawsTab>) => {
      state.selectedTab = action.payload
      state.model = null
      state.currentPage = 0
      state.isLoadingMore = false
    },
    selectTab: (state, action: PayloadAction<DrawsTab>) => {
      state.selectedTab = action.payload
    },
    // after deleting the draw we want to remove it from UI by just filtering, to avoid fetching the entire list again
    removePendingDrawFromList(state, action: PayloadAction<string>) {
      const drawId = action.payload

      if (state.model?.pendingDrawsData?.items) {
        state.model.pendingDrawsData.items = state.model?.pendingDrawsData.items.filter(it => it.drawId !== drawId)
        state.model.pendingDrawsData.totalItems--
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state, action) => {
      state.isLoadingMore = action.meta.arg
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      const [team, association, activeDrawsPage, pendingDrawsPage, completedDrawsPage, canceledDrawsPage, isLoadMore] = action.payload
      if (isLoadMore)
        state.currentPage++

      state.model = createModel(team, association, state.selectedTab, activeDrawsPage, pendingDrawsPage, completedDrawsPage, canceledDrawsPage, state.currentPage, state.model, isLoadMore)
      state.isLoading = false

    })

    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false
      state.isLoadingMore = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

    builder.addCase(updateDraw.pending, (state) => {
      state.isUpdating = true
    })

    builder.addCase(updateDraw.fulfilled, (state, action) => {
      const {drawId, salesStart, salesEnd} = action.meta.arg

      const activeDraw = state.model!.activeDrawsData.items.find(it => it.dto.id === drawId)

      if (activeDraw) {
        if (salesStart) {
          activeDraw.dto.salesStart = salesStart
          activeDraw.startTime = salesStart
        }
        if (salesEnd) {
          activeDraw.dto.salesEnd = salesEnd
          activeDraw.closingTime = salesEnd
        }
      }

      const pendingDraw = state.model!.pendingDrawsData.items.find(it => it.dto.id === drawId)

      if (pendingDraw) {
        if (salesStart) {
          pendingDraw.dto.salesStart = salesStart
          pendingDraw.salesStartTime = salesStart
        }
        if (salesEnd) {
          pendingDraw.dto.salesEnd = salesEnd
        }

      }

      state.isUpdating = false

    })

    builder.addCase(updateDraw.rejected, (state) => {
      state.isUpdating = false
    })

  },
})

// Actions
export const {initPage, removePendingDrawFromList} = drawsSlice.actions

// Selectors
export const selectModel = (state: RootState) => state.draws.model
export const selectLoadingError = (state: RootState) => state.draws.loadingError
export const selectSelectedTab = (state: RootState) => state.draws.selectedTab
export const selectIsInitialLoading = (state: RootState) => state.draws.isLoading && !state.draws.isLoadingMore
export const selectIsUpdating = (state: RootState) => state.draws.isUpdating

export default drawsSlice.reducer
