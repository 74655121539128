import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";
import {createModel, MyAccountPageModel} from "./model";
import {AdministratorDTO, administratorsApi, AdministratorUpdateDTO} from "../../../apis/administrators-api";
import {AssociationDTO} from "../../../apis/associations-api";
import {updateCurrentUser} from "../../../redux/settings";

export type MyAccountFormData = {
  personalNumber: string
  firstName: string
  lastName: string
  phone: string
  email: string
  notifyOnCloseDraw: boolean
}

interface MyAccountState {
  isLoading: boolean
  loadingError: ErrorObject | null
  model: MyAccountPageModel | null
  isSaving: boolean
  savingError: ErrorObject | null
  savedSuccessfully: boolean
}

const initialState: MyAccountState = {
  isLoading: false,
  loadingError: null,
  model: null,
  isSaving: false,
  savingError: null,
  savedSuccessfully: false
}

export const fetchData = createAsyncThunk<[AdministratorDTO, AssociationDTO]>(
  'myAccount/fetchData',
  async (payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState
    return Promise.all([
      administratorsApi.getAdministratorMe(),
      rootState.settings.selectedAssociation!
    ])
  },
)

export const saveData = createAsyncThunk<void, MyAccountFormData>(
  'myAccount/saveData',
  async (payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState
    const state = rootState.myAccount

    const dto: AdministratorUpdateDTO = {
      id: state.model!.userDTO.id!,
      firstName: payload.firstName,
      lastName: payload.lastName,
      phoneNumber: payload.phone,
      email: payload.email,
      customData: {
        notifyOnCloseDraw: payload.notifyOnCloseDraw
      },
      associationId: state.model!.administratorAssociation!.association.id
    }

    await administratorsApi.updateAdministrator(dto.id!, dto)
    thunkApi.dispatch(updateCurrentUser(payload))
  }
)

export const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    initPage: (state) => {
      state.isLoading = false
      state.loadingError = null
      state.isSaving = false
      state.savingError = null
      state.savedSuccessfully = false
    },
    clearPage: (state) => {
      state.savedSuccessfully = false
    }
  },
  extraReducers: (builder) => {

    // Fetch data
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchData.fulfilled, (state, action) => {
      const [user, association] = action.payload
      state.isLoading = false
      state.model = createModel(user, association)
    })

    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

    // Save data
    builder.addCase(saveData.pending, (state) => {
      state.isSaving = true
      state.savingError = null
    })

    builder.addCase(saveData.fulfilled, (state) => {
      state.isSaving = false
      state.savedSuccessfully = true
    })

    builder.addCase(saveData.rejected, (state, action) => {
      state.isSaving = false
      state.savingError = ErrorUtils.createErrorObjectFromAction(action)
    })

  },
})

// Actions
export const {initPage} = myAccountSlice.actions

// Selectors
export const selectIsLoading = (state: RootState) => state.myAccount.isLoading
export const selectLoadingError = (state: RootState) => state.myAccount.loadingError
export const selectModel = (state: RootState) => state.myAccount.model
export const selectIsSaving = (state: RootState) => state.myAccount.isSaving
export const selectSavingError = (state: RootState) => state.myAccount.savingError
export const selectSavedSuccessfully = (state: RootState) => state.myAccount.savedSuccessfully


export default myAccountSlice.reducer
