import axios from 'axios'
import {NavigateFunction} from "react-router-dom";
import {AppDispatch} from "../../store/store";
import {logout} from "../../pages/signup/redux/store";

let isInterceptorSet = false

/**
 * Axios instance that application should use for communicating with our service backend.
 */
export const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL + '/op/v1',
})

export const rootApiAxios = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL_ROOT,
})

export const setupResponseInterceptor = (navigate: NavigateFunction, dispatch: AppDispatch) => {
  if (isInterceptorSet) {
    return
  }

  const onFulfilledInterceptor = (response: any) => {
    return response
  }

  const onRejectInterceptor = async (error: any) => {
    const status = error?.response?.status
    if (status === 401) {
      dispatch(logout())
    } else if (status === 502) {
      // If we are not already in 'not-available' page, redirect there
      if (!window.location.pathname.endsWith('/not-available')) {
        navigate('/not-available')
      }
    } else {
      // RTK only supports four string properties in reject async action handlers:
      // name, message, stack, code
      // In order to avoid having to customize error serializer in the app we
      // do a workaround for it here

      let errorStack: string = ''

      // In case the response type was Blob, we need to resolve its text value before proceeding
      if (error?.response?.data) {
        if (isBlob(error.response.data)) {
          errorStack = await error.response.data.text()
        } else {
          errorStack = JSON.stringify(error.response.data)
        }
      }

      const serializedError = {
        code: error.code,
        message: error.message,
        name: error.name,
        stack: errorStack,
      }

      return Promise.reject(serializedError)
    }
  }

  apiAxios.interceptors.response.use(onFulfilledInterceptor,onRejectInterceptor)
  rootApiAxios.interceptors.response.use(onFulfilledInterceptor,onRejectInterceptor)

  isInterceptorSet = true
}

const isBlob = (value: any) => {
  if (typeof Blob === 'undefined') {
    return false
  }

  return value instanceof Blob || toString.call(value) === '[object Blob]'
}
