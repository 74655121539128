import React, {useCallback} from 'react'
import clsx from 'clsx'
import {ErrorObject, ErrorUtils} from "../../utils/ErrorUtils";
import {Popup} from "../popup/Popup";
import {Button} from "../buttons/Button";
import {ButtonGroup} from "../buttons/ButtonGroup";

type ConfirmationDialogProps = {
  onClose: () => void,
  visible?: boolean,
  onConfirm: () => void,
  confirmLabel?: string,
  closeLabel: string,
  text: string,
  secondaryText?: string
  saving?: boolean,
  error?: ErrorObject,
  icon?: any,
  iconClassName?: string,
  title: string,
  iconSize?: number,
}

export const ConfirmationDialog = ({
                                     onClose,
                                     visible = true,
                                     onConfirm,
                                     confirmLabel,
                                     closeLabel,
                                     text,
                                     secondaryText,
                                     saving,
                                     error,
                                     icon,
                                     iconClassName,
                                     title,
                                     iconSize = 700,
                                   }: ConfirmationDialogProps) => {
  const onCloseWrapper = useCallback(() => {
    if (!saving) {
      onClose()
    }
  }, [onClose, saving])

  const Icon = icon

  return (
    <Popup visible={visible} onClose={onCloseWrapper} className='shadow-dialog'>
      <div>
        {icon &&
          (
            <div className={clsx('flex items-center justify-center mt-8')}>
              <Icon size={iconSize} className={clsx('mr-1', iconClassName)}/>
            </div>
          )
        }
        <div className='p-4 space-y-2 text-navy text-center'>
          {
            title && (
              <div className={clsx('text-lg font-bold')}>
                {title}
              </div>
            )
          }
          <div className={clsx('text-sm whitespace-pre-line')}>
            {text}
          </div>
          {secondaryText && (
            <div className={clsx('bg-blue text-sm text-white rounded p-2 whitespace-pre-line')}>
              {secondaryText}
            </div>
          )}
          {
            error && (
              <div className='bg-red-500 text-sm text-white rounded p-2'>
                {ErrorUtils.getErrorMessage(error)}
              </div>
            )
          }
        </div>
      </div>
      <ButtonGroup>
        <Button label={closeLabel} onClick={onCloseWrapper} disabled={saving} variant='transparent'/>
        <Button label={confirmLabel} onClick={onConfirm} loading={saving}/>
      </ButtonGroup>
    </Popup>
  )
}
