import React from 'react'
import clsx from 'clsx'

import {Popup} from "../popup/Popup";
import {Button} from "../buttons/Button";

type NotificationDialogProps = {
  onClose: () => void,
  visible?: boolean,
  closeLabel?: string,
  text: string,
  secondaryText?: string
  icon: any,
  iconClassName?: string,
  title: string,
  iconSize?: number,
}

export const NotificationDialog = ({
                                     onClose,
                                     visible = true,
                                     closeLabel = 'Ok',
                                     text,
                                     secondaryText,
                                     icon,
                                     iconClassName,
                                     title,
                                     iconSize = 64,
                                   }: NotificationDialogProps) => {

  const Icon = icon

  return (
    <Popup visible={visible} onClose={onClose} className='shadow-dialog'>
      <div>
        {icon &&
          (
            <div className={clsx('flex items-center justify-center mt-8')}>
              <Icon size={iconSize} className={clsx('mr-1', iconClassName)}/>
            </div>
          )
        }
        <div className='p-4 space-y-2 text-navy text-center'>
          {
            title && (
              <div className={clsx('text-lg font-bold')}>
                {title}
              </div>
            )
          }
          <div className={clsx('text-sm whitespace-pre-line')}>
            {text}
          </div>
          {secondaryText && (
            <div className={clsx('text-sm whitespace-pre-line')}>
              {secondaryText}
            </div>
          )}
        </div>
      </div>
      <div>
        <Button label={closeLabel} onClick={onClose} className='w-full' variant='transparent'/>
      </div>
    </Popup>
  )
}
