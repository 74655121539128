import React from 'react'
import {ButtonGroup as TrinidadButtonGroup} from 'trinidad-ui'

type ButtonGroupProps = {
  children: React.ReactNode
  className?: string
}

export const ButtonGroup = ({children, className}: ButtonGroupProps) => {
  return (
    <TrinidadButtonGroup block className={className}>
      {children}
    </TrinidadButtonGroup>
  )
}
