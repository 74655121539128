import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectIsLoggedIn } from '../../redux/settings'

/**
 * Wrapper around routes that are available only to logged-in users.
 */
export const PrivateRoutesWrapper = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)

  return (
    isLoggedIn ? <Outlet /> : <Navigate to='/signup' />
  )
}
