import React from 'react'
import {PageContent} from "../page/PageContent";
import {H1, Subtitle} from "../text/Text";

export const NoTeamMessageComponent = () => {
  return (
    <PageContent className='mt-2'>
      <H1 textKey='components.noTeamMessage.title'/>
      <Subtitle className='mt-3.5' textKey='components.noTeamMessage.subtitle'/>
    </PageContent>
  )
}
